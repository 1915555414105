import axios, { CancelTokenSource } from "axios";

export function getNewCancelTokenSource(
	cancelTokenSource: CancelTokenSource = null,
	cancelExistingTokenSource: boolean = false
): CancelTokenSource {
	if (cancelTokenSource && cancelExistingTokenSource) {
		cancelTokenSource.cancel();
	}

	return axios.CancelToken.source();
}
