
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter, namespace, State } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { debounce, get } from "lodash";
import { CancelTokenSource } from "axios";

import { getDebouncePeriod } from "@/utils/debounce-helper";
import { getNewCancelTokenSource } from "@/utils/cancellableQueryHelper";
import {
	CancellableQuery,
	FeaturesList,
	PaginatedSearchQueryParams,
	UserGroup,
	UserPermissions,
	VueBootstrapField,
  	VueBootstrapFieldTableSortEvent
} from "@/store/types";
import { ServerDetails, ServerType, ServerModalStartTab } from "@/store/devices/types";
import { getNewServerDetailsTemplate } from "@/store/devices/templates";
import api from "@/services/api.service";
import DeleteDeviceModal from "@/components/device-setup/confirmation-modals/DeleteDeviceModal.vue";

const Devices = namespace("devices");

@Component({
	components: {
		"delete-device-modal": DeleteDeviceModal,
		"vue-perfect-scrollbar": VuePerfectScrollbar,
	}
})
export default class DeviceTable extends Vue {
	@Prop({ required: true, default: false, type: Boolean }) private visible;
	@Action private fetchUserGroups: () => Promise<void>;
	@Getter("getPermissions") private permissions: UserPermissions;
	@State private featuresList: FeaturesList;
	@State private UserGroups: UserGroup[];

	@Devices.Action private getDeviceTypes: () => Promise<void>;
	@Devices.Action private getFilteredServersPaginated: (
		params: CancellableQuery<PaginatedSearchQueryParams>
	) => Promise<void>;
	@Devices.Action private getServerLineProfiles: () => Promise<void>;
	
	@Devices.Mutation private setCurrentServer: (server: ServerDetails) => void;
	@Devices.Mutation setServerModalVisible: (IsVisible: boolean) => void;
	@Devices.Mutation private setServerModalStartTab: (startTab: ServerModalStartTab) => void;

	@Devices.State private serversList: ServerDetails[];
	@Devices.State private totalServers: number;
	@Devices.State private serverTypes: ServerType[];

	@Prop() devices: ServerDetails[];

	private axiosCancelTokenSource: CancelTokenSource = null;
	private defaultResponseGrouping: string = "";
	private isLoading: boolean = false;
	private recordsPage: number = 1;
	private recordsPageSize: number = 25;
	private recordsPageSizeMax: number = 200;
	private recordsPageSizeUpdated: number = this.recordsPageSize;
	private sortBy: string = "title";
	private sortDesc: boolean = false;
	private searchTerm: string = "";
	private showDeleteModal: boolean = false;

	private fields: VueBootstrapField[] = [
		{
			key: "title",
			label: "Title",
			sortable: true
		},
		{
			key: "serverTypeID",
			label: "Type",
			formatter: serverTypeID => {
				return this.getServerTypeTitle(serverTypeID);
			},
			sortable: true
		},
		{
			key: "groupID",
			label: "Area",
			formatter: groupID => {
				return this.getGroupTitle(groupID);
			},
			sortable: true
		},
		{
			key: "host",
			label: "Host",
			sortable: true
		},
		{
			key: "port",
			label: "Port",
			sortable: true
		}
	];

	private async created(): Promise<void> {
		if (this.hasAdvancedAlarmGroupingFlag) {
			this.addAdvancedAlarmGroupingSpecificFields();
		}

		if (this.hasEditPermission) {
			this.addEditPermissionsSpecificFields();
		}

		await Promise.all([
			this.getServers(),
			this.getDeviceTypes(),
			this.fetchUserGroups(),
			this.getDefaultResponseGrouping(),
			this.getServerLineProfiles()
		]);
	}

	private addAdvancedAlarmGroupingSpecificFields(): void {
		this.fields.push({
			key: "alarmGrouping",
			label: "Alarm Grouping",
			sortable: true,
			formatter: (value, key, item) => this.getAlarmGroupingTitle(item)
		});
	}

	private get hasEditPermission(): boolean {
		return this.permissions.isSystemAdmin || this.permissions.isAccountAdmin || this.permissions.canEditSiteSetup;
	}

	private addEditPermissionsSpecificFields(): void {
		this.fields.push({ key: "actions", label: "Actions", sortable: false, class: "text-center" });
	}

	private async getServers(): Promise<void> {
		this.isLoading = true;

		this.axiosCancelTokenSource = getNewCancelTokenSource(this.axiosCancelTokenSource, true);

		const sortBy = this.fields.find(f => f.key === this.sortBy)?.label.replace(/\s/g,'');

		const cancellableQuery: CancellableQuery<PaginatedSearchQueryParams> = {
			params: {
				page: this.recordsPage,
				pageSize: this.recordsPageSize,
				sortBy: sortBy || this.sortBy,
				sortDesc: this.sortDesc,
				searchTerm: this.searchTerm
			},
			cancelTokenSource: this.axiosCancelTokenSource
		};

		try {
			await this.getFilteredServersPaginated(cancellableQuery);
		}
		catch (e) {
			this.$notify({
				type: "error",
				title: "Error loading servers",
				text: `Unable to load servers`
			});
			console.error(e);
		}
		finally {
			this.isLoading = false;
		}
	}

	/** If the alarm doesn't have any grouping options set,
	 * then grouping is set in accordance with settings  */
	private async getDefaultResponseGrouping(): Promise<void> {
		const { data }: { data: string } = await api.getDefaultResponseGrouping();
		this.defaultResponseGrouping = data;
	}

	private getServerTypeTitle(serverTypeID: number): string {
		return this.serverTypes.find(st => st.serverTypeID == serverTypeID)?.title;
	}

	private getGroupTitle(groupID: number): string {
		return this.UserGroups.find(ug => ug.groupID == groupID)?.title;
	}

	private get hasAdvancedAlarmGroupingFlag(): boolean {
		return get(this.featuresList, ["Alarms", "AdvancedAlarmGrouping"]);
	}

	private getAlarmGroupingTitle(server: ServerDetails): string {
		if (server.isRaiseIndividual) {
			return "No Grouping";
		}

		if (server.isRaiseGrouped) {
			return "By Response";
		}

		return this.defaultResponseGrouping;
	}

	private get serversToDisplay(): ServerDetails[] {
		return this.serversList.map(server => {
			var isReadOnly = !!server.syncSystemId && !this.permissions.canOverrideGroupSync && !this.permissions.isSystemAdmin;
			return ({
				...server,
				readonly: isReadOnly,
				readonlyMessage: isReadOnly ? "You do not have permission to edit Sync Devices": null
			})
		});
	}

	private async onPageClick(page: number) {
		if (!this.visible || this.isLoading) {
			return;
		}

		this.recordsPage = page || this.recordsPage;

		await this.getServers();
	}

	private async onRecordsPerPageClick() {
		let pageSize = +this.recordsPageSizeUpdated;

		if (pageSize) {
			if (pageSize > this.recordsPageSizeMax) {
				pageSize = this.recordsPageSizeMax;
			}
			if (pageSize !== this.recordsPageSize) {
				this.recordsPage = 1;
			}
			this.recordsPageSize = pageSize;
		}
		this.recordsPageSizeUpdated = this.recordsPageSize;
		this.onPageClick(this.recordsPage);
	}

	private handleAddDevice(): void {
		this.setServerModalStartTab(ServerModalStartTab.Server);
		this.setCurrentServer(getNewServerDetailsTemplate());
		this.setServerModalVisible(true);
	}

	private handleEditClick(server: ServerDetails): void {
		this.setServerModalStartTab(ServerModalStartTab.Server);
		this.setCurrentServer(server);
		this.setServerModalVisible(true);
	}

	private handleDeleteClick(server: ServerDetails): void {
		this.setCurrentServer(server);
		this.showDeleteModal = true;
	}

	private handleDeleteModalClose(): void {
		this.showDeleteModal = false;
		this.setCurrentServer(null);
	}

	private handleSearchInput = debounce(async (searchTerm: string): Promise<void> => {
		await this.performSearch(searchTerm);
	}, getDebouncePeriod());

	private async performSearch(searchTerm: string): Promise<void> {
		this.searchTerm = searchTerm;
		await this.getServers();
	}

	private deviceEditComponentLoaded(): void {
		// this.setIsLoading(false);
	}

	private async onSortChange(event: VueBootstrapFieldTableSortEvent): Promise<void> {
		if (!this.visible || this.isLoading) {
			return;
		}

		this.sortBy = event.sortBy || this.sortBy;
		this.sortDesc = event.sortDesc;

		await this.getServers();
	}
}
