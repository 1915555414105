
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace, Getter } from 'vuex-class';
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { FeaturesList, UserPermissions } from '@/store/types';
import { get } from 'lodash';
import { DeviceTypeIdentifier, ServerDetails, ServerType, ServerModalStartTab, SyncSystem } from "@/store/devices/types";
import ChildDeviceTabWrapper from './tabs/ChildDeviceTabWrapper.vue';
import DeviceEdit from './editing/DeviceEdit.vue';
import GetConfig from './GetConfig.vue';
import DeviceAlarmSetup from '../setup/alarm-setup/DeviceAlarmSetup.vue';
import SyncEdit from './sync/SyncEdit.vue';
import GroupSync from './sync/GroupSync.vue';

const Devices = namespace("devices");

export interface ServerModelDetails {
	invalid: boolean;
	selectedServer: ServerDetails;
}

export interface SyncModelDetails {
	invalid: boolean;
	selectedSync: SyncSystem;
}
@Component({
	components: {
		"vue-perfect-scrollbar": VuePerfectScrollbar,
		"child-device-tab-wrapper": ChildDeviceTabWrapper,
		"device-edit": DeviceEdit,
		"sync-edit": SyncEdit,
		"group-sync": GroupSync,
		"get-config": GetConfig,
		"device-alarm-setup": DeviceAlarmSetup
	}
})
export default class ServerModal extends Vue {
	@Devices.State private currentServer: ServerDetails;
	@Devices.State private serverTypes: ServerType[];
	@Devices.State private serverModalOpened: boolean;
	@Devices.State private serverModalStartTab: ServerModalStartTab;
	@Devices.State private syncSystems: SyncSystem[];

	@Devices.Mutation private setCurrentServer: (server: ServerDetails) => void;
	@Devices.Mutation setServerModalVisible: (IsVisible: boolean) => void;

	@Devices.Action private updateServer: (server: ServerDetails) => Promise<void>;
	@Devices.Action private addServer: (server: ServerDetails) => Promise<void>;
	@Devices.Action private addOrUpdateSyncSystem: (sync: SyncSystem) => Promise<void>;
	@Getter private getFeaturesList: FeaturesList;
	@Getter getPermissions: UserPermissions;

	private baseChildDeviceTypeIds: number[] = [];
	private tabIndex: number = 0;
	private serverModelDetails: ServerModelDetails = { invalid: true, selectedServer: null };
	private syncModelDetails: SyncModelDetails = { invalid: true, selectedSync: null };

	@Watch("serverModalOpened")
	private setStartTab(open: boolean): void {
		if (!open) {
			return;
		}

		if (this.serverModalStartTab == ServerModalStartTab.Server)
		{
			if(this.currentServer && this.currentServer.syncSystemId){
				this.tabIndex = 2;
				return;
			}
			this.tabIndex = 0
		}
		else if (this.serverModalStartTab == ServerModalStartTab.Sync)
		{
			this.tabIndex = 0;
		}
	}

	private get deviceInfoTabIndex(): number {
		if (this.syncSetupEnabled && this.currentServer && (this.currentServer.syncSystemId == -1 || this.currentServer.syncSystemId > 0)){
			return 2;
		}
		return 0;
	}

	private async created(): Promise<void>{
		this.addChildDeviceTypes();
	}

    private get isEditingServer(): boolean {
		return this.currentServer && this.currentServer.serverID > 0;
	}

    private get modalTitle(): string {
		if (this.isEditingServer) {
			return `${this.currentServer.readonly ? "Viewing" : "Editing"} ${this.currentServer.title}`;
		}

		return "Create new " + (this.serverModalStartTab == ServerModalStartTab.Sync ? "Sync" : "Device");
	}

	private get modalIcon(): string {
		var iconClass = "fa-edit"
		if (this.isEditingServer && this.currentServer && this.currentServer.readonly) {
			iconClass = "fa-eye"
		}

		return "fas mr-1 " + iconClass;
	}

    private handleEditModalCloseClick(): void {
		this.setServerModalVisible(false);
		this.setCurrentServer(null);
	}

    private addChildDeviceTypes(): void {
		for (let deviceTypeIdentifier in DeviceTypeIdentifier) {
			const id = Number(deviceTypeIdentifier);

			if (!isNaN(id)) {
				this.baseChildDeviceTypeIds.push(id);
			}
		}
	}

    private get childDeviceTypeIds(): number[] {
		if (this.isEditingServer) {
			return this.baseChildDeviceTypeIds;
		}

		return [];
	}

	private handleServerModelDetailsChanged(serverModelDetails: ServerModelDetails): void {
		this.serverModelDetails = serverModelDetails;
	}

	private syncDetailsChanged(syncModelDetails: SyncModelDetails): void {
		this.syncModelDetails = syncModelDetails;
	}

	private async handleServerSaveClick(): Promise<void> {
		if (this.serverModelDetails.selectedServer.serverID) {
			let selectedServer = this.serverModelDetails.selectedServer;
			selectedServer.extraValue = this.extraValue;
			await this.updateServer(selectedServer);
			return;
		}

		await this.addServer(this.serverModelDetails.selectedServer);
	}

	private async handleSyncSaveClick(): Promise<void> {
		if(!this.syncModelDetails){
			return;
		}

		await this.addOrUpdateSyncSystem(this.syncModelDetails.selectedSync)
	}

	private get deviceTabIndex(): number {
		return this.tabIndex
			- (this.showGetConfig ? 1 : 0)
			- ((this.syncSetupEnabled && this.currentServer && this.currentServer.syncSystemId > 0 ? 2 : 0));
	}

	private get isGetConfigInDevicesEnabled(): boolean {
		return get(this.getFeaturesList, ["Devices", "ShowGetConfig"]);
	}

	private get showGetConfig(): boolean {
		return (this.serverModelDetails != null && this.serverModelDetails.selectedServer != null && this.selectedServerType != null &&
		        this.selectedServerType.canGetConfig && this.currentServer && !this.currentServer.syncSystemId && this.isGetConfigInDevicesEnabled);
	}

	private get isGetConfigDisabled(): boolean {
		return (this.serverModelDetails.selectedServer == null || this.serverModelDetails.selectedServer.serverID == null || this.serverModelDetails.selectedServer.serverID == 0);
	}

	private get getConfigTooltipText(): string {
		return this.isGetConfigDisabled ? "Please click save to create the server before getting config" : "";
	}

	private get selectedServerType(): ServerType {
		return this.serverTypes.find(st => st.serverTypeID === this.serverModelDetails.selectedServer?.serverTypeID);
	}

	public get extraValue(): string {
		if (this.currentServer) {
			return this.currentServer.extraValue;
		}

		return "";
	}

	private get allowsAlarms(): boolean {
		const serverType: ServerType = this.serverTypes.find(
				st => st.serverTypeID === this.currentServer.serverTypeID
			);
		return serverType && serverType.numAlarmInputs !== 0;
	}

	private get getAlarmTitleText(): string {
		let text = "";
		if (!this.allowsAlarms) {
			text = `Alarms are not supported on this device`;
		}
		return text;
	}

	private get alarmCount(): number {
		let aCount = 0;
		if (this.currentServer != null && this.currentServer.deviceTypeCounts != null && this.currentServer.deviceTypeCounts.alarms != null) {
			aCount = this.currentServer.deviceTypeCounts.alarms;
		}

		return aCount;
	}

	private get selectedServerId(): number {
		let id = 0;
		if (this.currentServer != null) {
			id =  this.currentServer.serverID;
		}

		return id;
	}

	private get syncSetupEnabled(): boolean {
		return get(this.getFeaturesList, ["Devices", "AdvancedSetup", "SyncSystem"]);
	}

	private get syncIsSuiteSync(): boolean {
		const linkedSyncSystem = this.syncSystems.firstOrDefault(ss => ss.serverId == this.selectedServerId);

		if (linkedSyncSystem == null) {
			return false;
		}
		return linkedSyncSystem.isSuiteSync;
	}

	private get syncIsDeviceSync(): boolean {
		const linkedSyncSystem = this.syncSystems.firstOrDefault(ss => ss.serverId == this.selectedServerId);

		if (linkedSyncSystem == null) {
			return false;
		}
		return linkedSyncSystem.isDeviceSync;
	}

	private get isSuiteSyncEnabled(): boolean {
		return get(this.getFeaturesList, ["Suite", "SuiteSync"]);
	}

	private get userCanConfigureSuiteSync(): boolean {
		return this.getPermissions.canConfigureSuiteSync;
	}

	private get userCanSaveSync(): boolean {
		if (this.isSuiteSyncEnabled && this.syncIsSuiteSync) {
			return this.userCanConfigureSuiteSync && !this.syncModelDetails.invalid;
		}

		return !this.syncModelDetails.invalid;
	}
}
