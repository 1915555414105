
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GroupSyncQueue, GroupSyncQueueState, GroupSyncAudit } from '@/types/sv-data/devices/SyncSystem';
import api from '@/services/api.service';
import vselect3 from "vselect3";
import GenericTable, { TableHeader } from '@/components/table/generic-table.vue';
import { formatDateMixin } from "@/mixins";
import { PaginatedSearchQueryParams } from '@/store/types';
const { fromNow } = formatDateMixin.methods;

@Component({
	components: {
        "vue-select" : vselect3,
		"generic-table": GenericTable,
    }
})
export default class GroupSyncHistory extends Vue {
    @Prop({required: true, type: Number, default: null })
    private groupSyncId: number;

	@Prop({type: Number, required: true})
	private groupSyncCompletedKey: number;

    private groupSyncQueues: GroupSyncQueue[] = [];
    private syncQueueAudit: GroupSyncAudit[] = [];
    private selectedSyncQueue: number = null;
    private isLoadingAudit: boolean = false;
    private pageNumber: number = 1;
    private pageLength: number = 25;

    private columns: TableHeader[] = [
		{
			title: "Audit",
			key: "audit",
			order: 2,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "Audit Message",
			searchable: true,
			visible: true,
			dataType: "input",
            isTermLabel: true,
			useCustomCell: true,
		},
		{
			title: "Date / Time",
			key: "dateCreated",
			order: 1,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "Area",
			searchable: true,
			visible: true,
			dataType: "input",
            isTermLabel: true,
			useCustomCell: true,
            width:"175px"
		},
	];


    private async mounted(): Promise<void> {
        this.retrieveGroupSyncQueue();
    }

	@Watch("groupSyncCompletedKey")
	private async onGroupSyncCompletedKeyChanged(): Promise<void> {
		await this.retrieveGroupSyncQueue();
		if (this.selectedSyncQueue) {
			await this.retrieveQueueAudit(this.selectedSyncQueue, null);
		}
	}

    @Watch("groupSyncId")
    private async retrieveGroupSyncQueue(): Promise<void> {
        try
        {
            if (!this.groupSyncId) {
                this.groupSyncQueues = [];
                return;
            }
            this.groupSyncQueues = (await api.syncQueueForGroupSync(this.groupSyncId)).filter(gsq => gsq.lastUpdated);
            this.pageNumber = 1;
            this.pageLength = 25;
        }
        catch(err)
        {
            console.error(err);
            this.groupSyncQueues = [];
        }
    }

    @Watch("selectedSyncQueue")
    private async retrieveQueueAudit(newQueueId: number, oldQueueId: number): Promise<void> {
        if (newQueueId == oldQueueId) {
            return;
        }

        if (!newQueueId) {
            this.syncQueueAudit = [];
            return;
        }

        try
        {
            this.isLoadingAudit = true;
            this.syncQueueAudit = await api.auditTrailForGroupSyncQueue(newQueueId);
        }
        catch(err)
        {
            console.error(err);
            this.syncQueueAudit = [];
        }
        this.isLoadingAudit = false;
    }


	private friendlyStateText(state: string): string {
		switch (state)
		{
			case GroupSyncQueueState.preQueued:
				return "Pending";
			case GroupSyncQueueState.queued:
				return "Queued";
			case GroupSyncQueueState.processing:
				return "Processing";
			case GroupSyncQueueState.failed:
				return "Failed";
			case GroupSyncQueueState.processed:
				return "Processed";
			case GroupSyncQueueState.partialProcessed:
				return "Partially Processed";
			case GroupSyncQueueState.noData:
				return "No data";
			default:
				return "";
		}
	}

    private queueOptionLabel(queueItem: GroupSyncQueue): string {
        if (!queueItem) {
            return "";
        }
        return `${this.friendlyStateText(queueItem.state)} - ${fromNow(queueItem.lastUpdated)}`;
    }

	private updatePage(paginatedSearchQueryParams?: PaginatedSearchQueryParams): void {
        this.pageNumber = paginatedSearchQueryParams.page;
        this.pageLength = paginatedSearchQueryParams.pageSize;
    }

    private get groupSyncHistoryPage(): GroupSyncAudit[] {
        return this.syncQueueAudit.slice(((this.pageNumber - 1) * this.pageLength), (this.pageNumber * this.pageLength));
    }
}
