
import { Component, Mixins, Prop } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import vselect3 from "vselect3";
import { isEqual } from "lodash";

import ChildDeviceEditMixin from "@/components/device-setup/editing/ChildDeviceEditMixin";
import { CameraSettingsMeta, ServerDetails, ServerType } from "@/store/devices/types";
import PresetEdit from "./PresetEdit.vue";

const Devices = namespace("devices");

@Component({
	components: {
		"v-select-3": vselect3,
		"preset-edit": PresetEdit
	},
	validations: {
		device: { required },
		hasSettingsMetaChanged: {
			required: requiredIf(function() {
				return this.isEditing && isEqual(this.device.cameraSettings, this.originalCameraSettings);
			})
		},
	}
})
export default class CameraEdit extends Mixins(ChildDeviceEditMixin, validationMixin) {
	@Prop({ type: Number }) private serverId: number;

	@Devices.State private serversList: ServerDetails[];
	@Devices.State private serverTypes: ServerType[];
	@Getter getFeature: (featureList: string[]) => boolean;

	private originalCameraSettings: CameraSettingsMeta = null;
	private telemetryEnabled: boolean = false;

	public created(): void {
		this.originalCameraSettings = { ...this.device.cameraSettings };
		this.telemetryEnabled = this.device.cameraSettings.telemServerId === 0;
	}

	private get qualityOptions(): string[] {
		return ["Default", "Lowest", "Low", "Medium", "High", "Highest"];
	}

	private get telemetrySpeedOptions(): string[] {
		return ["Default", "Slowest", "Slow", "Medium", "Fast", "Fastest"];
	}

	private get hasMatrixBuiltIn(): boolean {
		if (!this.serverId) {
			return false;
		}

		const server: ServerDetails = this.serversList.find(s => s.serverID === this.serverId);

		if (!server) {
			return false;
		}

		const serverType: ServerType = this.serverTypes.find(st => st.serverTypeID == server.serverTypeID);

		if (!serverType) {
			return false;
		}

		return serverType.hasMatrixBuiltIn;
	}

	private handleTelemetryEnabledInput(): void {
		this.device.cameraSettings.telemServerId = this.telemetryEnabled ? 0 : -1;
	}

	private get advancedPTZIsEnabled(): boolean {
		return this.getFeature(["Devices", "AdvancedSetup", "PTZ"]);
	}
}
