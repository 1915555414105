
import { Component, Prop, Watch, Emit, Mixins } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { namespace, Getter } from "vuex-class";
import { SyncSystem, ServerType, ServerDetails } from "@/store/devices/types";
import { UserPermissions, FeaturesList } from "@/store/types";
import { get, isEqualWith } from "lodash";

import ApplianceList from "@/components/devices/ApplianceList.vue";
import vselect3 from "vselect3";
import { required, minValue, requiredIf } from "vuelidate/lib/validators";
import { SyncModelDetails } from "../ServerModal.vue";

const UserManagement = namespace("userManagement");
const Devices = namespace("devices");

@Component({
	components: {
		"v-select-3": vselect3,
		"appliance-list": ApplianceList
	},
	validations: {
		selectedSyncSystem: {
			serverTypeId: { required, minValue: minValue(1) },
			port: { minValue: minValue(0) },
			title: { required }
		},
		hasDataChanged: {
			required: requiredIf(function() {
				const selectedSync = {
					...this.selectedSyncSystem
				};

				const storeSync = {
					...this.storedSyncSystem
				};

				return isEqualWith(
					selectedSync,
					storeSync,
					(propFromSelectedSync, propFromStoreSync) => {
						if (
							(!propFromSelectedSync) &&
							(!propFromStoreSync)
						) {
							return true;
						}
					}
				);
			})
		}
	}
})
export default class SyncEdit extends Mixins(validationMixin) {
	@Getter("getFeaturesList") private featuresList: FeaturesList;
	@Getter private getUserTenantGroupId: number;

	@Devices.Getter("getSortedServerTypes") private sortedServerTypes: ServerType[];

	@Getter getPermissions: UserPermissions;
	@UserManagement.Action fetchPermissionsState: () => Promise<void>;

	@Devices.State private serverTypes: ServerType[];
	@Devices.State private syncSystems: SyncSystem[];
	@Devices.State private currentServer: ServerDetails;

	@Prop({ type: Boolean, default: false })
	private readonly: boolean;

	public selectedSyncSystem: SyncSystem = this.defaultSyncSystem();
	private storedSyncSystem: SyncSystem = this.defaultSyncSystem();
	private settingSync: boolean = false;

	private defaultSyncSystem(): SyncSystem {
		return {
			syncSystemId: null,
			serverId: null,
			serverTypeId: null,
			applianceId: null,
			title: "",
			host: "",
			port: null,
			username: null,
			password: null,
			extraValue: null,
			tenantId: this.getUserTenantGroupId,
			isSuiteSync: null,
			isDeviceSync: null,
			linkedSyncSystemId: null,
			linkedSyncSystemTitle: null
		};
	}

	@Watch("selectedSyncSystem", { deep: true })
	@Watch("storedSyncSystem", { deep: true })
	@Emit("syncDetailsUpdated")
	private handleSyncDetailsChanged(): SyncModelDetails {
		if (this.settingSync) {
			return;
		}
		return { invalid: this.$v.$invalid, selectedSync: this.selectedSyncSystem };
	}

	@Watch("currentServer", { deep: true })
	private handleServerDetailsChanged(): void {
		if (!this.currentServer) {
			return;
		}
		this.settingSync = true;
		if (this.currentServer.syncSystemId == -1) {
			this.selectedSyncSystem = this.defaultSyncSystem();
			this.storedSyncSystem = this.defaultSyncSystem();
		} else if (this.currentServer.syncSystemId > 0) {
			var syncSystem = this.syncSystems.find(ss => ss.syncSystemId == this.currentServer.syncSystemId);
			this.selectedSyncSystem = { ...syncSystem };
			this.storedSyncSystem = { ...syncSystem };
		}
		this.settingSync = false;
	}

	private async created(): Promise<void> {
		this.handleServerDetailsChanged();
		await this.fetchPermissionsState();
	}

	private get selectedServerType(): ServerType {
		return this.serverTypes.find((st) => st.serverTypeID === this.selectedSyncSystem?.serverTypeId);
	}

	private get isApplianceEnabled(): boolean {
		return get(this.featuresList, ["Devices", "Appliances"]);
	}

	private get isSuiteSyncEnabled(): boolean {
		return get(this.featuresList, ["Suite", "SuiteSync"]);
	}

	private get isDeviceSyncEnabled(): boolean {
		return get(this.featuresList, ["Devices", "AdvancedSetup", "SyncSystem", "DeviceSync"]);
	}

	private get userCanConfigureSuiteSync(): boolean {
		return this.getPermissions.canConfigureSuiteSync;
	}

	private get userCanConfigureDeviceSync(): boolean {
		return this.getPermissions.canConfigureDeviceSync;
	}

	private get extraValueLabel(): string {
		const serverType = this.selectedServerType;

		if (serverType && serverType.extraValueName) {
			return serverType.extraValueName;
		}

		return "Extra Value";
	}

	private get isLinkedSystem(): boolean {
		return this.selectedSyncSystem?.linkedSyncSystemId != null;
	}

	private get linkedSystemTitle(): string | null {
		return this.selectedSyncSystem?.linkedSyncSystemTitle ?? "Unknown";
	}

	private get isReadOnlyForm(): boolean {
		return this.readonly || this.isLinkedSystem;
	}
}
