import { render, staticRenderFns } from "./DeviceAlarmSetup.vue?vue&type=template&id=061d2d9a&scoped=true"
import script from "./DeviceAlarmSetup.vue?vue&type=script&lang=ts"
export * from "./DeviceAlarmSetup.vue?vue&type=script&lang=ts"
import style0 from "./DeviceAlarmSetup.vue?vue&type=style&index=0&id=061d2d9a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "061d2d9a",
  null
  
)

export default component.exports