
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";

import { FeaturesList, PaginatedSearchQueryParams, UserPermissions } from "@/store/types";
import api from "@/services/api.service";
import CameraSelect from "@/components/form/CameraSelect.vue";
import { truncateString } from "@/filters";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import _ from 'lodash';
import PaginatedSearch from "@/mixins/PaginatedSearch";
import AutomationMixin from "@/mixins/AutomationMixin";
import ResponsesPagedResponse from "@/types/sv-data/responses/ResponsesPagedResponse";
import { Response, AlarmGrouping, Script, YesNoOption } from "@/store/responses/types";
import { DeviceDetails, ServerDetails } from '@/store/devices/types';
import vselect3 from "vselect3";
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import { get } from "lodash";
import ResponseActionSetup from './ResponseActionSetup.vue';
import { ResponseAction } from "@/store/response-actions/types";
import CustomFieldTableView from "@/components/custom-fields/CustomFieldModalTableView.vue";
import { CustomFieldDto, CustomFieldTableTypes } from "@/store/custom-fields/types";
import { customFieldMixins } from "@/mixins/customFieldMixins";
import { ModalItemCSV } from '@/store/csv-form-handler/types';
import { MapCustomFieldToModalItem } from "@/utils/CustomFieldLogic";
import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
import GenericUpdateModal from '@/components/table/generic-update-modal.vue';

interface ResponseRow extends Response {
    readOnly?: boolean;
    readOnlyMessage?: string;
    deleteMode?: boolean;
    scriptTitle: string;
    script: Script;
    grouping: AlarmGrouping;
    linkedCameras: DeviceDetails[];
	responseActions?: ResponseAction[];
    maskDurationLimit?: number;
}

interface ServerTypeEvent {
    eventNum: number,
    title: string,
}

const Devices = namespace("devices");
const CustomFields = namespace("customFields");
const GenericTableStore = namespace("GenericTable");

const { mapArrayFieldsToObject, mapObjectFieldsToArray } = customFieldMixins.methods;

@Component({
    components: {
		"vue-perfect-scrollbar": VuePerfectScrollbar,
		"v-select-3": vselect3,
		"area-tree-select": AreaTreeSelect,
        "camera-select": CameraSelect,
		"custom-field-modal-table-view": CustomFieldTableView,
		"generic-table": GenericTable,
		ResponseActionSetup
	},
	filters: {
        truncateString
    },
})
export default class DeviceAlarmSetup extends Mixins(PaginatedSearch, validationMixin, AutomationMixin) {
	$refs!: {
		customFieldTableView: CustomFieldTableView;
		genericUpdateModal: GenericUpdateModal;
		genericTable: GenericTable
	}

	@Prop({ default: null })
    private selectedServerId: number;

	@Prop({ default: false, type: Boolean })
    private readonly: boolean;

    @Prop({ default: false, type: Boolean })
    private inModal: number;

	@Prop({ default: null })
	private selectedGroupId: number;

	@Getter("getPermissions")
    private permissions: UserPermissions;
	@Getter("getFeaturesList") featuresList: FeaturesList;

	@Devices.State("serversList")
    private serversList: ServerDetails[];

	@CustomFields.State private alarmCustomFields: CustomFieldDto[];
	@CustomFields.Action retrieveCustomFields: ({ tableType, live }) => Promise<void>;

	@GenericTableStore.Getter getModalRow: any;

	private totalRecords: number = 0;
	private isLoading: boolean = false;
	private serverModalAlarmLoaded: boolean = false;
	private responses: ResponseRow[] = [];
	private responseScripts: Script[] = [];
    private selectedResponseActions: ResponseAction[] = [];
	private selectedResponse: ResponseRow = null;
	private serverTypeEvents: ServerTypeEvent[] = [];
    private maxNumInputValue: number = 2147483647;
    private showModal: boolean = false;
    private responseActionEdited: boolean = false;
	private modalIsOpen: boolean = false;

	private readonly customFieldTableType: number = CustomFieldTableTypes.Alarm;

	/**
	*	@returns Table columns
	*/

    private yesNoNotSet: YesNoOption[] = [
        {
            title: "Yes",
            value: true
        },
        {
            title: "No",
            value: false
        },
        {
            title: "Not set",
            value: null
        }
    ];

    private alarmGroupingOptions: AlarmGrouping[] = [
        {
            title: "By Area",
            isRaiseIndividual: false,
            isRaiseGrouped: false
        },
        {
            title: "By Alarm",
            isRaiseIndividual: false,
            isRaiseGrouped: true
        },
        {
            title: "Not Grouped",
            isRaiseIndividual: true,
            isRaiseGrouped: false
        },
        {
            title: "Not Set",
            isRaiseIndividual: null,
            isRaiseGrouped: null
        }
    ];


    private get deleteMode(): boolean
    {
        return this.selectedResponse && this.selectedResponse.deleteMode;
    }

    private get responseTitle(): string
    {
        if (!this.selectedResponse || !this.selectedResponse.responseID)
        {
            return "";
        }

        return this.responses.find(r => r.responseID == this.selectedResponse.responseID)?.title;
    }

	private get showAutomations(): boolean {
		return  !!get(this.featuresList, ["Alarms", "CameraAutomation"]) &&
		(!!this.permissions.canViewAutomations || !!this.permissions.canEditAutomations);
	}

	private get showLinkedCamerasInModal(): boolean {
		return !!get(this.featuresList, ["Devices"]) && !get(this.featuresList, ["Alarms", "CameraAutomation"]);
	}

	private get showMaskDurationLimit(): boolean {
		return get(this.featuresList, ["Alarms", "Masking", "MaxMaskDuration"], false);
	}

	private get isCustomFieldAlarmsEnabled(): boolean {
		return get(this.featuresList, ["CustomFields", "Alarms"]);
	}

	public get hasCanViewCustomFieldsPermission(): boolean {
		return this.permissions.canViewCustomFields;
	}

	private async mounted(): Promise<void> {
		this.responseScripts = await api.getResponseScripts();

		if (this.isCustomFieldAlarmsEnabled) {
			await this.retrieveCustomFields({ tableType: CustomFieldTableTypes.Alarm, live: true });
		}

		await this.updateResponses();
	}

    private async putAlarm(response: ResponseRow): Promise<void>
    {
        if (!response.serverID)
        {
            response.serverID = this.selectedServerId;
        }

        response.isRaiseIndividual = response.grouping.isRaiseIndividual;
        response.isRaiseGrouped = response.grouping.isRaiseGrouped;

		if (this.isCustomFieldAlarmsEnabled) {
			response.customFields = mapObjectFieldsToArray(response, "id", "value", "cf");
		} else {
			response.customFields = null;
		}

        var result = null;
        if (!response.responseID)
        {
            result = await api.createDeviceResponse(response);
        }
        else
        {
            result = await api.updateResponse(response);
        }

        if (!result || !result.data)
        {
            return;
        }

        this.selectedResponse = result.data;
        await this.updateResponseActions(response.responseID, response.responseActions, this.selectedResponseActions);
        await this.updateResponses();
        this.showModal = false;
        this.selectedResponseActions = [];
        this.selectedResponse = null;
	}

    private async updateResponseActions(responseId: number, newResponseActions: ResponseAction[], oldResponseActions:  ResponseAction[]){
		// Cant use foreach loop as it does not play nicely with async calls
		// Create/Update responseActions
		if(newResponseActions != null)
		{
			for(let i = 0; i < newResponseActions.length; i++ ) {
				try {
					var newAutomation = newResponseActions[i];
                    newAutomation.description = null;


					// If we have no response its a new automation
					if(!newAutomation.responseActionID)
					{
						newAutomation.responseID = responseId;

						await api.createAlarmAutomation(responseId, newAutomation);
					}
					else {

						// Check to see if changes have been made to an item before we try and hit the backend
						// Shallow compare
						var oldAutomation = oldResponseActions.find(r => r.responseActionID == newResponseActions[i].responseActionID)

						var updated = newAutomation.objectID != oldAutomation.objectID
									|| newAutomation.responseActionTypeID != oldAutomation.responseActionTypeID
									|| newAutomation.settingsMeta != oldAutomation.settingsMeta
						if(updated)
						{
							await api.updateAlarmAutomation(responseId, newAutomation);
						}
					}
				}
				catch (error) {
					this.$notify({
					type: "error",
					title: "Failed to create/update automation",
					text: error.response.data
					});
				}
			}
		}
		// Delete responseActions that no longer exist
		if(oldResponseActions  && oldResponseActions.length > 0)
		{
			for(let i = 0; i < oldResponseActions.length ; i++)
			{
				if(!newResponseActions.find(r => r.responseActionID == oldResponseActions[i].responseActionID))
				{
					try {
                        await api.deleteAlarmAutomation(this.selectedResponse.responseID, oldResponseActions[i].responseActionID)
                    }
                    catch (error) {
                        this.$notify({
                            type: "error",
                            title: "Failed to delete automation",
                            text: error.response.data
					    });
				    }
				}
			}
		}
	}

    private async deleteAlarm(response: ResponseRow): Promise<void>
    {
		await api.deleteResponse(response.responseID);
		await this.updateResponses();
		this.selectedResponse = null;
		this.showModal = false;
	}

    private async loadServerTypes(serverId: number): Promise<void>
    {
        if (!serverId)
        {
            this.serverTypeEvents = [];
            return;
        }

        const selectedServer = this.serversList.find(s => s.serverID == serverId);
        if (selectedServer)
        {
            this.serverTypeEvents = (await api.loadServerEventTypes(selectedServer.serverTypeID, null)).data;
        }
        else
        {
            this.serverTypeEvents = [];
        }

        if (!this.serverTypeEvents)
        {
            this.serverTypeEvents = []
        }

        this.serverTypeEvents.unshift({eventNum: -1, title: "Default"});
    }

    @Watch("selectedServerId")
	public async updateResponses(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<void> {
		try
        {
			this.isLoading = true;
            await this.loadServerTypes(this.selectedServerId);

            const cancellableQuery = this.generateNewPaginatedSearchRequest(paginatedSearchQueryParams);

			if (this.selectedServerId)
            {
				cancellableQuery.params.serverId = this.selectedServerId;
			}

            cancellableQuery.params.isEmailAlarms = false;


			let result: ResponsesPagedResponse = await api.getResponses(cancellableQuery);
			var responseData: ResponseRow[] = result.data as ResponseRow[];

			if (responseData) {
				responseData = responseData.map(response => {
					response.readOnly = !!response.groupSyncId && !this.permissions.canOverrideGroupSync && !this.permissions.isSystemAdmin;
                    response.deleteMode = false;
					return response;
				});
			}

			this.responses = responseData;
            if (this.responses)
            {
                for(var i = 0; i < this.responses.length; i++)
                {
                    var response = this.responses[i];
                    response.script = this.responseScripts.find(rs => rs.scriptID === response.scriptID);
                    response.grouping = this.alarmGroupingOptions.find(ag =>
                        ag.isRaiseGrouped == response.isRaiseGrouped &&
                        ag.isRaiseIndividual == response.isRaiseIndividual);

                    if (this.showAutomations) {
                        response.responseActions = await api.getAlarmAutomations(response.responseID)
                    }
                }
            }

			this.totalRecords = result.totalRecords;
		}
		catch (ex)
        {
			console.error("Unexpected error searching alarms: " + ex);
		}
        finally
        {
			this.isLoading = false;
		}
    }

    private async openModal(response: ResponseRow, deleteMode: boolean = false): Promise<void>
    {
        this.selectedResponseActions = []

        if (response)
        {
            this.selectedResponse = response;
            this.selectedResponse.deleteMode = deleteMode;

            if(this.selectedResponse.responseActions)
            {
                this.selectedResponseActions = this.selectedResponse.responseActions;
            }

			if (this.isCustomFieldAlarmsEnabled) {
				mapArrayFieldsToObject(this.selectedResponse, this.selectedResponse.customFields, this.alarmCustomFields,"id", "value", "cf");
			}
        }
        else
        {
            this.selectedResponse = {
                responseID: null,
                groupID: null,
                serverTypeEventNum: -1,
                title: null,
                script: null,
                input1: null,
                input2: -1,
                extraValue: "",
                priority: null,
                grouping: null,
                isRaiseIndividual: null,
                isRaiseGrouped: null,
                linkedCameras: [],
                autoHandle: null,
                deleteMode: false,
				responseActions: [],
                maskDurationLimit: null,
				customFields: []
            } as ResponseRow

            if (this.selectedServerId)
            {
                this.selectedResponse.serverID = this.selectedServerId;
            }
        }

        await this.loadServerTypes(this.selectedResponse.serverID);
        this.responseActionEdited = false;
		this.modalIsOpen = true;
    }

    @Watch("getModalRow", { deep:true })
    private async serverIdWatch(newResponse: ResponseRow | null, oldResponse: ResponseRow | null): Promise<void>
    {
		if (!newResponse.serverID && !this.inModal)
		{
			this.serverTypeEvents = [];
			if (this.selectedResponse)
			{
				this.selectedResponse.serverTypeEventNum = -1;
			}
			return;
		}

		if (newResponse.serverID != oldResponse?.serverID) {
			await this.loadServerTypes(newResponse.serverID);
			if (this.selectedResponse)
			{
				if(!!oldResponse.serverID && newResponse.serverID != oldResponse.serverID) {
					this.selectedResponse.serverTypeEventNum = -1;
					this.$refs.genericTable.updateNewEntry(this.selectedResponse, ["serverTypeEventNum"]);
				}

				if(!this.selectedResponse.responseID)
				{
					if(!oldResponse.serverID) {
						this.selectedResponse.serverTypeEventNum = -1;
						this.$refs.genericTable.updateNewEntry(this.selectedResponse, ["serverTypeEventNum"]);
					}

					const server = this.serversList.find(sl => sl.serverID == newResponse.serverID);
					this.selectedResponse.groupID = server?.groupID;
					this.$refs.genericTable.updateNewEntry(this.selectedResponse, ["groupID"]);
				}
			}
		}

		if (this.inModal && !this.selectedResponse?.responseID && !this.serverModalAlarmLoaded && this.modalIsOpen) {
			this.setupServerModalAlarm();
		}
	}

	private setupServerModalAlarm(): void {
		let newAlarm = {
                responseID: null,
				serverID: this.selectedServerId,
                groupID: this.selectedGroupId,
                serverTypeEventNum: -1,
                title: null,
                script: null,
                input1: null,
                input2: -1,
                extraValue: "",
                priority: null,
                grouping: null,
                isRaiseIndividual: null,
                isRaiseGrouped: null,
                linkedCameras: [],
                autoHandle: null,
                deleteMode: false,
				responseActions: [],
                maskDurationLimit: null,
				customFields: []
            } as ResponseRow;
		this.selectedResponse.serverID = this.selectedServerId;
		this.$refs.genericTable.updateNewEntry(newAlarm, ["serverID", "groupID", "serverTypeEventNum"]);
		this.serverModalAlarmLoaded = true;
	}

    private responseActionInput(input:  ResponseAction[]): void {
        this.selectedResponse.responseActions = input;
        this.responseActionEdited = true;
    }

	private async openCustomFieldModal(): Promise<void> {
		this.$refs.customFieldTableView.showCustomFieldModal();
	}

	private get genericTableColumns(): TableHeader[] {
		const GenericTableColumns: TableHeader[] = [
			{
				title: "Alarm Name",
				key: "title",
				sortable: true,
				visible: true,
				order: 1,
				sortOrder: 0,
				sortOrderReversed: false,
				isTermLabel: true
			},
			{
				title: "Device",
				key: "deviceTitle",
				sortable: true,
				visible: true,
				order: 2,
				sortOrder: 1,
				sortOrderReversed: false
			},
			{
				title: "Area",
				key: "groupTitle",
				sortable: true,
				visible: true,
				order: 3,
				sortOrder: 2,
				sortOrderReversed: false
			},
			{
				title: "Action Plan",
				key: "scriptTitle",
				sortable: true,
				visible: true,
				order: 4,
				sortOrder: 3,
				sortOrderReversed: false
			},
			{
				title: "Input 1",
				key: "input1",
				sortable: true,
				visible: true,
				order: 5,
				sortOrder: 4,
				sortOrderReversed: false
			},
			{
				title: "Input 2",
				key: "input2",
				sortable: true,
				visible: true,
				order: 6,
				sortOrder: 5,
				sortOrderReversed: false
			},
			{
				title: "Extra Value",
				key: "extraValue",
				sortable: true,
				visible: true,
				order: 7,
				sortOrder: 6,
				sortOrderReversed: false
			},
			{
				title: "Priority",
				key: "priority",
				sortable: true,
				visible: true,
				order: 8,
				sortOrder: 7,
				sortOrderReversed: false
			},
			{
				title: "Grouping",
				key: "grouping",
				sortable: true,
				visible: true,
				order: 9,
				sortOrder: 8,
				sortOrderReversed: false,
				useCustomCell: true
			},
			{
				title: "Linked Camera",
				key: "linkedCameras",
				sortable: false,
				disable: !this.showLinkedCamerasInModal,
				order: 10,
				sortOrder: 9,
				sortOrderReversed: false,
				useCustomCell: true
			},
			{
				title: "Automations",
				key: "responseActions",
				sortable: false,
				disable: !this.showAutomations,
				order: 11,
				sortOrder: 10,
				sortOrderReversed: false,
				useCustomCell: true,
			},
			{
				title: "Max Mask Duration (minutes)",
				key: "maskDurationLimit",
				sortable: false,
				disable: !this.showMaskDurationLimit,
				order: 12,
				sortOrder: 11,
				sortOrderReversed: false
			},
		]

		return GenericTableColumns
	}

	private get modalItems (): ModalItemCSV[]{
		var modalItems: ModalItemCSV[] = [
			{
				title: "Device",
				key: "serverID",
				dataType: "vselect3",
				placeholder: "Search",
				data: this.serversList,
				required: true,
				order: 1,
				readOnlyMethod: (item, row) => this.inModal || !!row.responseID,
				props:
				{
					reduce: (sl) => sl.serverID,
					value: this.selectedServerId
				},
			},
			{
				title: "Area",
				key: "groupID",
				dataType: "component",
				placeholder: "Select an area",
				data: AreaTreeSelect,
				props: {
					reduce: a => a.id,
					clearable: false,
					"append-to-body": true
				},
				required: true,
				order: 2

			},
			{
				title: "Event Type",
				key: "serverTypeEventNum",
				dataType: "vselect3",
				maxLength: 250,
				placeholder: "Select an event type",
				data: this.serverTypeEvents,
				props: {
					reduce: (ste) => ste.eventNum,
				},
				required: true,
				order: 3
			},
			{
				title: "Title",
				key: "title",
				dataType: "text",
				maxLength: 250,
				placeholder: "Title",
				props: {
					id: "title",
					formatter: (v) => !v ? v : v.substring(0,250)
				},
				required: true,
				order: 4
			},
			{
				title: "Action Plan",
				key: "scriptID",
				dataType: "vselect3",
				maxLength: 250,
				placeholder: "Select an action plan",
				data: this.responseScripts,
				order: 5,
				props: {
					reduce: (rs) => rs.scriptID,
				},
			},
			{
				title: "Input 1",
				key: "input1",
				dataType: "number",
				placeholder: "Input 1",
				props: {
					id: "input-1"
				},
				required: true,
				validations: {
					IsDeviceAlarmInput1Valid: helpers.withParams(
						{type: "IsDeviceAlarmInput1Valid", errorMessage: `Input 1 must be between -1 and ${this.maxNumInputValue}` },
						value => {
							return -1 <= value && value <= this.maxNumInputValue;
						}
					)
				},
				order: 6
			},
			{
				title: "Input 2",
				key: "input2",
				dataType: "number",
				placeholder: "Input 2",
				props: {
					id: "input-2"
				},
				required: true,
				validations: {
					IsDeviceAlarmInput2Valid: helpers.withParams(
						{type: "IsDeviceAlarmInput2Valid", errorMessage: `Input 2 must be between -1 and ${this.maxNumInputValue}` },
						value => {
							return -1 <= value && value <= this.maxNumInputValue;
						}
					)
				},
				order: 7
			},
			{
				title: "Extra Value",
				key: "extraValue",
				dataType: "text",
				maxLength: 250,
				placeholder: "Extra Value",
				props: {
					id: "extra-value",
					formatter: (v) => !v ? v : v.substring(0,100)
				},
				order: 8
			},
			{
				title: "Priority",
                key: "priority",
                dataType: "number",
                defaultValue: null,
                required: false,
                placeholder: "Enter the alarm priority",
				props: {
					id: "priority"
				},
				validations: {
					IsDeviceAlarmPriorityValid: helpers.withParams(
						{type: "IsDeviceAlarmPriorityValid", errorMessage: `Priority must be between 0 and ${this.maxNumInputValue}` },
						value => {
							return !value || (0 <= value && value <= this.maxNumInputValue);
						}
					)
				},
				order: 9
			},
			{
				title: "Linked Cameras",
				key: "linkedCameras",
				dataType: "component",
				visible: this.showLinkedCamerasInModal,
				maxLength: 250,
				placeholder: "Search all areas",
				data: CameraSelect,
				props: {
					id: "linkedCameras",
					multiple: true
				},
				order: 10
			},
			{
				title: "Grouping",
				key: "grouping",
				dataType: "vselect3",
				maxLength: 250,
				placeholder: "Select an alarm grouping",
				data: this.alarmGroupingOptions,
				required: true,
				order: 11
			},
			{
				title: "Auto Handle",
				key: "autoHandle",
				dataType: "vselect3",
				maxLength: 250,
				placeholder: "Select an auto handle setting",
				data: this.yesNoNotSet,
				props: {
					reduce: (ah) => ah.value,
				},
				order: 12
			}
		] as ModalItemCSV[];

		if (this.showAutomations && this.selectedResponse?.responseID && this.selectedResponse.responseID > 0) {
			var automationsModalItem: ModalItemCSV = {
				title: "Automations",
				key: "responseActions",
				dataType: "component",
				data: ResponseActionSetup,
				props: {
					value: this.selectedResponse.responseActions,
					groupID: this.selectedResponse.groupID,
					input: this.responseActionInput
				},
				order: modalItems.length + 1
			}

			modalItems.push(automationsModalItem as ModalItemCSV);
		}

		if (this.showMaskDurationLimit) {
			modalItems.push({
				title: "Max Mask Duration (minutes)",
				key: "maskDurationLimit",
				dataType: "number",
				maxLength: 10,
				min: 0,
				placeholder: "Enter the Max Mask Duration",
				csvComponent: 'input',
				visible: this.showMaskDurationLimit,
				defaultValue: null,
				props: {
					id: "maskDurationLimit"
				},
				validations: {
					IsDeviceAlarmMaxMaskingDurationValid: helpers.withParams(
						{ type: "IsDeviceAlarmMaxMaskingDurationValid", errorMessage: `Max Masking Duration must be between 0 and ${this.maxNumInputValue}` },
						value => {
							if (!value || !this.showMaskDurationLimit) {
								return true;
							}
							return (value >= 0 && value <= 2147483647);
						}
					)
				},
				order: modalItems.length + 1,
			});
		}

		if (this.isCustomFieldAlarmsEnabled && this.alarmCustomFields != null){
			var shellModalItem: ModalItemCSV = {
				title: "",
				key: "",
				dataType: "",
				readOnly: false,
				order: modalItems.length + 1,
				csvComponent: null
			};

			for (let i = 0; i < this.alarmCustomFields.length; i++) {
				let alarmCustomFieldModalItem = MapCustomFieldToModalItem(this.alarmCustomFields[i], shellModalItem);
				modalItems.push(alarmCustomFieldModalItem as ModalItemCSV);
			}
		}

		return modalItems;
	}

	private onModalClose(): void {
		this.serverModalAlarmLoaded = false;
		this.modalIsOpen = false;
	}
}
