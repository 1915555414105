
import { VNode } from "vue";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { namespace, Mutation, Getter } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vselect3 from "vselect3";
import { validationMixin } from "vuelidate";
import { maxValue, minValue, required, requiredIf } from "vuelidate/lib/validators";
import { debounce, get } from "lodash";

import { getDebouncePeriod } from "@/utils/debounce-helper";
import AudioEdit from "@/components/device-setup/editing/AudioEdit.vue";
import CameraEdit from "@/components/device-setup/editing/CameraEdit.vue";
import RelayEdit from "@/components/device-setup/editing/RelayEdit.vue";

import {
	DeviceDetails,
	DeviceType,
	DeviceTypeIdentifier,
	ServerDetails,
	ServerType,
	ServersWithEnabledDeviceTypesParams
} from "@/store/devices/types";
import { FeaturesList } from '@/store/types';
import { SubscriptionDto } from '@/store/subscription/types';
import AreaTreeSelect from '@/components/form/AreaTreeSelect.vue';

const Devices = namespace("devices");
const Subscription = namespace("subscription");

const childDeviceComponents: Record<string, any> = {
	[DeviceTypeIdentifier.Audio]: AudioEdit,
	[DeviceTypeIdentifier.Camera]: CameraEdit,
	[DeviceTypeIdentifier.Output]: RelayEdit,
	[DeviceTypeIdentifier.Door]: RelayEdit
};

@Component({
	components: {
		"v-select-3": vselect3,
		"vue-perfect-scrollbar": VuePerfectScrollbar,
		"area-tree-select": AreaTreeSelect,
	},
	validations: {
		device: {
			serverID: { required, minValue: minValue(1) },
			title: { required },
			input1: { required, minValue: minValue(-1), maxValue: maxValue(2147483647) },
			input2: { minValue: minValue(-1), maxValue: maxValue(2147483647) },
			groupID: { required, minValue: minValue(1) },
		},
		dataUnchanged: {
			required: requiredIf(function() {
				return (
					this.device &&
					this.device.title === this.deviceFromStore.title &&
					this.device.serverID === this.deviceFromStore.serverID &&
					this.device.input1.toString() === this.deviceFromStore.input1.toString() &&
					(this.device.extraValue ?? "") === (this.deviceFromStore.extraValue ?? "") &&
					this.device.input2.toString() === this.deviceFromStore.input2.toString() &&
					this.device.groupID === this.deviceFromStore.groupID &&
					this.device.presets === this.deviceFromStore.presets
				);
			})
		}
	}
})
export default class ChildDeviceEdit extends Mixins(validationMixin) {
	@Mutation private setIsLoading: (loading: boolean) => void;

	@Devices.Action private addDevice: (device: DeviceDetails) => Promise<void>;
	@Devices.Action private getServer: (serverId: number) => Promise<void>;
	@Devices.Action private getServersWithEnabledDeviceTypes: (
		params: ServersWithEnabledDeviceTypesParams
	) => Promise<void>;
	@Devices.Action private updateDevice: (device: DeviceDetails) => Promise<void>;
	@Devices.Mutation private setEditChildDeviceModalVisibility: (visible: boolean) => void;
	@Devices.State private currentDeviceTypeIdentifier: DeviceTypeIdentifier;
	@Devices.State private currentServer: ServerDetails;
	@Devices.State("device") private deviceFromStore: DeviceDetails;
	@Devices.State private editChildDeviceModalVisible: boolean;
	@Devices.State("serversList") private serversListFromStore: ServerDetails[];
	@Devices.State("serverTypes") private serverTypesFromStore: ServerType[];

	@Subscription.State subscription: SubscriptionDto;
	@Subscription.Action fetchSubscription: () => Promise<void>;

	@Getter("getFeaturesList") featuresList: FeaturesList;

	private childDeviceIsInvalid: boolean = true;
	private device: DeviceDetails = null;
	private searchTerm: string = "";
	private selectedServer: ServerDetails = null;
	private serversList: ServerDetails[] = [];

	private async created(): Promise<void> {
		this.device = { ...this.deviceFromStore };
		this.filterServersListForDeviceType();

		if ((this.creatingFromMainTab || this.isEditing) && this.notEnoughServersDisplayed) {
			const serversWithEnabledDeviceTypesParams: ServersWithEnabledDeviceTypesParams = { page: 1, pageSize: 100 };

			this.adjustQueryForDeviceType(serversWithEnabledDeviceTypesParams);

			await this.getServersWithEnabledDeviceTypes(serversWithEnabledDeviceTypesParams);
			this.filterServersListForDeviceType();
		}

		if (this.isEditing && this.deviceServerNotInList) {
			await this.getServer(this.device.serverID);
			this.filterServersListForDeviceType();
		}

		this.setInitialSelectedServer();

		if (this.isBillingEnabled) {
			await this.fetchSubscription();
		}
	}

	private filterServersListForDeviceType(): void {
		this.serversList = this.serversListFromStore.filter(
			s =>
				!s.syncSystemId &&
				this.serverTypesForThisDeviceType.some(st => st.serverTypeID === s.serverTypeID) &&
				(!this.searchTerm || s.title.toLowerCase().includes(this.searchTerm.toLowerCase()))
		);
	}

	private get serverTypesForThisDeviceType(): ServerType[] {
		switch (this.device.deviceTypeID) {
			case DeviceType.Audio:
				return this.serverTypesFromStore.filter(st => st.numAudioInputs !== 0);
			case DeviceType.Relay:
				return this.serverTypesFromStore.filter(st => st.numRelays !== 0);
			default:
				return this.serverTypesFromStore.filter(st => st.numCameras !== 0);
		}
	}

	private get isBillingEnabled(): boolean {
		return (!!get(this.featuresList, ["Billing"]) || get(this.featuresList, ["Suite"])) && this.currentDeviceTypeIdentifier === DeviceTypeIdentifier.Camera;
	}

	private get isCameraSubscriptionValid(): boolean {
		if (this.subscription && this.subscription.limitedSubscription) {
			if ((this.subscription.cameras + this.cameraUsageDifference) > this.subscription.camerasLimit) {
				return false;
			}
		}
		return true;
	}

	private get cameraUsageDifference(): number {
		return this.isEditing ? 0 : 1;
	}

	private get notEnoughServersDisplayed(): boolean {
		return this.serversList.length < 100;
	}

	private get deviceServerNotInList(): boolean {
		return this.serversList.find(s => s.serverID == this.device.serverID) === undefined;
	}

	private setInitialSelectedServer(): void {
		this.selectedServer = this.serversListFromStore.find(s => s.serverID === this.device.serverID);
	}

	@Watch("selectedServer")
	private selectedServerChange(): void {
		if(this.device && this.device.deviceID == null && this.selectedServer != null)
		{
			this.device.groupID = this.selectedServer.groupID;
		}
	}

	private get isEditing(): boolean {
		return this.device.deviceID > 0;
	}

	private get title(): string {
		const deviceType = DeviceTypeIdentifier[this.currentDeviceTypeIdentifier];

		return this.isEditing
			? `Editing ${deviceType} Device "${this.deviceFromStore.title}"`
			: `Create ${deviceType} Device`;
	}

	private get creatingFromMainTab(): boolean {
		return this.currentServer === null;
	}

	private handleServerSelected(): void {
		this.device.serverID = this.selectedServer && this.selectedServer.serverID;
	}

	private get childDeviceComponent(): VNode {
		return childDeviceComponents[this.currentDeviceTypeIdentifier];
	}

	private handleValidationChanged(invalid: boolean): void {
		this.childDeviceIsInvalid = invalid;
	}

	private handleDeviceDetailsChanged(device: DeviceDetails): void {
		this.device = {
			...device,
			title: this.device.title,
			serverID: this.device.serverID,
			input1: this.device.input1
		};
	}

	private handleModalHidden(): void {
		this.setEditChildDeviceModalVisibility(false);
	}

	private get deviceIsInvalid(): boolean {
		return this.$v.device.$invalid || (this.$v.dataUnchanged.$invalid && this.childDeviceIsInvalid) || !this.isCameraSubscriptionValid;
	}

	private async handleSaveClick(): Promise<void> {
		//API will default input2 to 0 if it's empty
		if (!this.device.input2 && this.device.input2 !== 0) {
			this.device.input2 = -1;
		}

		if (this.isEditing) {
			await this.updateDevice(this.device);
		} else {
			await this.addDevice(this.device);
		}

		this.setEditChildDeviceModalVisibility(false);
	}

	private handleServerSearch(searchTerm: string, loading: (boolean) => void): void {
		loading(true);
		this.debounceSearch(this, searchTerm, loading);
	}

	private debounceSearch = debounce(
		async (
			component: InstanceType<typeof ChildDeviceEdit>,
			searchTerm: string,
			loading: (boolean) => void
		): Promise<void> => {
			component.searchTerm = searchTerm;
			await this.searchServers(loading);
		},
		getDebouncePeriod()
	);

	private async searchServers(loading: (boolean) => void): Promise<void> {
		if (this.searchTerm) {
			const serversWithEnabledDeviceTypesParams: ServersWithEnabledDeviceTypesParams = {
				page: 1,
				pageSize: 100,
				searchTerm: this.searchTerm
			};

			this.adjustQueryForDeviceType(serversWithEnabledDeviceTypesParams);

			await this.getServersWithEnabledDeviceTypes(serversWithEnabledDeviceTypesParams);
		}

		this.filterServersListForDeviceType();

		loading(false);
	}

	private adjustQueryForDeviceType(serversWithEnabledDeviceTypesParams: ServersWithEnabledDeviceTypesParams): void {
		switch (this.device.deviceTypeID) {
			case DeviceType.Camera: {
				serversWithEnabledDeviceTypesParams.camerasEnabled = true;
				break;
			}
			case DeviceType.Audio: {
				serversWithEnabledDeviceTypesParams.audiosEnabled = true;
				break;
			}
			case DeviceType.Relay: {
				serversWithEnabledDeviceTypesParams.relaysEnabled = true;
				break;
			}
		}
	}

	private get usesInput2(): boolean {
		const serverType: ServerType = this.serverTypesFromStore.find(
			st => st.serverTypeID == this.selectedServer?.serverTypeID
		);

		if (!serverType) {
			return false;
		}

		switch (this.device.deviceTypeID) {
			case DeviceType.Camera:
				return serverType.usesExtraCameraIds;
			case DeviceType.Audio:
				return serverType.usesExtraAudioIds;
			case DeviceType.Relay:
				return serverType.usesExtraRelayIds;
		}

		return false;
	}

	private get usesExtraValue(): boolean {
		const serverType: ServerType = this.serverTypesFromStore.find(
			st => st.serverTypeID == this.selectedServer?.serverTypeID
		);

		if (!serverType) {
			return false;
		}

		return serverType.usesDeviceExtraValue;
	}
}
