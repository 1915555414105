
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { DeviceDetails, DeviceTypeIdentifier } from "@/store/devices/types";

const Devices = namespace("devices");

@Component({})
export default class DeleteChildDeviceModal extends Vue {
	@Devices.Action private deleteDevice: (device: DeviceDetails) => Promise<void>;
	@Devices.Mutation private setDeleteChildDeviceModalVisibility: (visible: boolean) => void;
	@Devices.State private currentDeviceTypeIdentifier: DeviceTypeIdentifier;
	@Devices.State private deleteChildDeviceModalVisible: boolean;
	@Devices.State private device: DeviceDetails;

	private handleCloseDeleteModalClick(): void {
		this.setDeleteChildDeviceModalVisibility(false);
	}

	private async handleDeleteConfirmClick(): Promise<void> {
		await this.deleteDevice(this.device);
		this.setDeleteChildDeviceModalVisibility(false);
	}

	private get deviceTypeTitle(): string {
		return DeviceTypeIdentifier[this.currentDeviceTypeIdentifier].toLowerCase();
	}
}
