
import { Component, Emit, Mixins, Watch } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { namespace, Getter } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vselect3 from "vselect3";
import { maxLength, minValue, required, requiredIf } from "vuelidate/lib/validators";
import { isEqualWith, get } from "lodash";

import { ServerModelDetails } from "@/components/device-setup/ServerModal.vue";
import { ServerDetails, ServerLineProfile, ServerType } from "@/store/devices/types";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import ApplianceList from "@/components/devices/ApplianceList.vue";
import { FeaturesList } from "@/store/types";

const Devices = namespace("devices");

const AlarmGroupingNone: string = "No Grouping";
const AlarmGroupingResponse: string = "By Response";
const AlarmGroupingDefault: string = "By Area";

@Component({
	components: {
		"area-tree-select": AreaTreeSelect,
		"v-select-3": vselect3,
		"vue-perfect-scrollbar": VuePerfectScrollbar,
		"appliance-list": ApplianceList
	},
	validations: {
		selectedServer: {
			groupID: { required, minValue: minValue(1) },
			serverTypeID: { required, minValue: minValue(1) },
			title: { required },
			applianceId: { required: requiredIf(function () {
				return this.isApplianceOnly;
			}) },
			accountNumber: { maxLength: maxLength(20) },
		},
		hasDataChanged: {
			required: requiredIf(function () {
				const selectedServerWithValidationFieldsOnly = {
					...this.selectedServer,
					deviceTypeCounts: null,
				};

				const storeServerWithValidationFieldsOnly = {
					...this.currentServer,
					deviceTypeCounts: null,
				};

				return isEqualWith(
					selectedServerWithValidationFieldsOnly,
					storeServerWithValidationFieldsOnly,
					(propFromSelectedServer, propFromStoreServer) => {
						if (
							(propFromSelectedServer === null || propFromSelectedServer === "") &&
							(propFromStoreServer === null || propFromStoreServer === "")
						) {
							return true;
						}
					}
				);
			}),
		},
	},
})
export default class DeviceEdit extends Mixins(validationMixin) {
	@Getter("getFeaturesList") private featuresList: FeaturesList;
	@Devices.Action private getDeviceTypeCounts: (serverId: number | null) => Promise<void>;
	@Devices.State private currentServer: ServerDetails;
	@Devices.State private serverLineProfiles: ServerLineProfile[];
	@Devices.State private serverTypes: ServerType[];
	@Devices.Mutation private setExtraValue: (extraValue: string) => void;
	@Devices.Getter("getSortedServerTypes") private sortedServerTypes: ServerType[];

	private alarmGrouping: string = AlarmGroupingDefault;
	private selectedServer: ServerDetails = null;
	private deviceEditLoaded: boolean = false;
	private areaSelectLoaded: boolean = false;

	private async created(): Promise<void> {
		this.selectedServer = { ...this.currentServer };

		if (this.isEditing) {
			this.setAlarmGroupingOption();
			await this.getDeviceTypeCounts(this.selectedServer.serverID);
		}

		this.deviceEditLoaded = true;
	}

	private updateExtraValue(ev) {
		this.setExtraValue(ev);
	}

	private setAlarmGroupingOption(): void {
		if (this.selectedServer.isRaiseIndividual) {
			this.alarmGrouping = AlarmGroupingNone;
		} else if (this.selectedServer.isRaiseGrouped) {
			this.alarmGrouping = AlarmGroupingResponse;
		} else {
			this.alarmGrouping = AlarmGroupingDefault;
		}
	}

	@Watch("componentLoaded")
	private onComponentLoaded(newValue: boolean, oldValue: boolean) {
		if (newValue && newValue != oldValue) {
			this.$emit("component-loaded");
		}
	}

	private get componentLoaded(): boolean {
		return this.deviceEditLoaded && this.areaSelectLoaded;
	}

	private get isEditing(): boolean {
		return this.selectedServer.serverID > 0;
	}

	private get alarmGroupingOptions(): string[] {
		return [AlarmGroupingNone, AlarmGroupingResponse, AlarmGroupingDefault];
	}

	private handleAlarmGroupingSelected(): void {
		switch (this.alarmGrouping) {
			case AlarmGroupingNone:
				this.selectedServer.isRaiseIndividual = true;
				this.selectedServer.isRaiseGrouped = false;
				break;
			case AlarmGroupingResponse:
				this.selectedServer.isRaiseIndividual = false;
				this.selectedServer.isRaiseGrouped = true;
				break;
			default:
				this.selectedServer.isRaiseIndividual = false;
				this.selectedServer.isRaiseGrouped = false;
		}
	}

	@Watch("selectedServer", { deep: true })
	@Watch("currentServer", { deep: true })
	@Emit("server-model-details-changed")
	private handleServerDetailsChanged(): ServerModelDetails {
		return { invalid: this.$v.$invalid, selectedServer: this.selectedServer };
	}

	private get extraValueLabel(): string {
		return `${this.extraValuePlaceholder}:`;
	}

	private get extraValuePlaceholder(): string {
		const serverType = this.selectedServerType;

		if (serverType && serverType.extraValueName) {
			return serverType.extraValueName;
		}

		return "Extra Value";
	}

	private get selectedServerType(): ServerType {
		return this.serverTypes.find((st) => st.serverTypeID === this.selectedServer.serverTypeID);
	}

	private get serverTypeIsAlarmPanel(): boolean {
		const serverType = this.selectedServerType;

		if (serverType && serverType.isAlarmPanel) {
			return true;
		}

		return false;
	}

	private areaSelectComponentLoaded(): void {
		this.areaSelectLoaded = true;
	}

	public get extraValue(): string {
		if (this.currentServer) {
			return this.currentServer.extraValue;
		}

		return "";
	}

	private get isApplianceEnabled() {
		return get(this.featuresList, ["Devices", "Appliances"]);
	}

	private get isApplianceOnly() {
		return get(this.featuresList, ["Devices", "Appliances", "ApplianceOnly"]);
	}

	private get isApplianceSelected() {
		return !!this.selectedServer.applianceId;
	}

	private get readonly(): boolean {
		return this.selectedServer && !!this.selectedServer.readonly
	}
}
