export interface GroupSyncModel {
    groupSyncId: number;
    title: string;
    syncSystemId: number;
    identifier: string;
    interval: number;
    lastRun?: Date;
    enabled: boolean;
    groupId: number;
    additionalConfig: string;
	lastQueueState?: string;
}

export interface GroupSyncAdditionalConfig {
    AllowDelete?: boolean;
    UpdateGroupId?: boolean;
    UpdateSettingsMeta?: boolean;
	MaskNewAlarmPoints?: boolean;
}

export interface SyncStateResponse{
	state: string;
	groupSyncId: number;
}

export interface GroupSyncQueue {
    syncQueueId: number;
    groupSyncId: number;
    state: string;
    created: Date;
    lastUpdated?: Date;
}

export enum GroupSyncQueueState {
    preQueued = "PreQueue",
    reQueue = "ReQueue",
    queued = "Queued",
    processing = "Processing",
    failed = "Failed",
    processed = "Processed",
    partialProcessed = "PartiallyProcessed",
    noData = "NoData",
}

export interface GroupSyncAudit {
    auditId: number;
    audit: string;
    dateCreated: Date;
}
