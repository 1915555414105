
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { ServerDetails } from "@/store/devices/types";

const Devices = namespace("devices");

@Component({})
export default class DeleteDeviceModal extends Vue {
	@Prop({ type: Boolean, required: true }) private showModal: boolean;

	@Devices.Action private deleteServer: (serverId: number) => Promise<void>;
	@Devices.Action private getDeviceTypeCounts: (serverId: number) => Promise<void>;
	@Devices.State private currentServer: ServerDetails;

	private handleCloseDeleteModalClick(): void {
		this.closeModal();
	}

	private async handleDeleteConfirmClick(): Promise<void> {
		await this.deleteServer(this.currentServer.serverID);
		await this.getDeviceTypeCounts(null);
		this.closeModal();
	}

	@Emit("close-modal")
	private closeModal(): void {}
}
