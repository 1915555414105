import { Component, Emit, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { cloneDeep } from "lodash";

import {
	getAudioSettingsDefaults,
	getCameraSettingsDefaults,
	getDoorRelaySettingsDefaults,
	getOutputRelaySettingsDefaults
} from "@/store/devices/templates";
import { DeviceDetails, DeviceTypeIdentifier } from "@/store/devices/types";

const Devices = namespace("devices");

@Component
export default class ChildDeviceEditMixin extends Vue {
	@Devices.State private currentDeviceTypeIdentifier: DeviceTypeIdentifier;
	@Devices.State("device") private deviceFromStore: DeviceDetails;

	public device: DeviceDetails = null;

	public created(): void {
		this.device = cloneDeep(this.deviceFromStore);
		this.setSettingsMetasIfNeeded();
	}

	private setSettingsMetasIfNeeded(): void {
		switch (this.currentDeviceTypeIdentifier) {
			case DeviceTypeIdentifier.Camera: {
				if (!this.device.cameraSettings) {
					this.device.cameraSettings = getCameraSettingsDefaults();
				}
				break;
			}
			case DeviceTypeIdentifier.Audio: {
				if (!this.device.audioSettings) {
					this.device.audioSettings = getAudioSettingsDefaults();
				}
				break;
			}
			case DeviceTypeIdentifier.Output: {
				if (!this.device.relaySettings) {
					this.device.relaySettings = getOutputRelaySettingsDefaults();
				}
				break;
			}
			case DeviceTypeIdentifier.Door: {
				if (!this.device.relaySettings) {
					this.device.relaySettings = getDoorRelaySettingsDefaults();
				}

				break;
			}
		}
	}

	public get isEditing(): boolean {
		return this.device.deviceID > 0;
	}

	@Watch("$v.$invalid")
	@Emit("validation-changed")
	private handleValidationChange($invalid: boolean): boolean {
		return $invalid;
	}

	@Watch("device", { deep: true })
	@Emit("device-details-changed")
	private handleDeviceDetailsChanged(): void {}
}
