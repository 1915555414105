
import { Component, Mixins, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { validationMixin } from "vuelidate";

import ChildDeviceEditMixin from "@/components/device-setup/editing/ChildDeviceEditMixin";
import { DevicePreset, DeviceDetails, DeviceConfigurationPreset, DeviceConfigurationPresetTableItems, DeviceConfigurationPresetPayload } from '@/store/devices/types';
import { VueBootstrapField } from '@/store/types';

const Devices = namespace("devices");

@Component({})
export default class PresetEdit extends Mixins(ChildDeviceEditMixin, validationMixin) {

	@Devices.Action private getCameraPresets: (device: DeviceDetails) => Promise<DeviceConfigurationPreset[]>;
	@Devices.Action private createOrUpdateCameraPreset: (presetConfigPayload : DeviceConfigurationPresetPayload) => Promise<boolean>;
	@Devices.Action private deleteCameraPreset: (presetConfigPayload : DeviceConfigurationPresetPayload) => Promise<boolean>;

	private nilNumber: string = "-897432897543";

	public presets: DeviceConfigurationPresetTableItems[] = [];

	public get dataFields(): VueBootstrapField[] {
		return this.presetConfigFields.filter(preset => preset.key !== "actions");
	}

	public get presetConfigFields(): VueBootstrapField[] {

		let fields = [
			{
				key: "position",
				label: "PTZ Preset Position",
				editable: false,
				dataType: "number",
				tdClass: "align-middle",
			} as VueBootstrapField,
			{
				key: "name",
				label: "PTZ Preset Name",
				editable: true,
				dataType: "text"
			} as VueBootstrapField,
			{
				key: "actions",
				label: "Actions",
				editable: false,
				tdClass: "align-middle"
			} as VueBootstrapField
		] as VueBootstrapField[];

		return fields;
	}

	public async mounted(): Promise<void> {
		if (!this.device)
		{
			return;
		}

		let presetsFromDb = await this.getCameraPresets(this.device);
		this.presets = presetsFromDb.map(preset => { return {...preset, editing: false, creating: false} as DeviceConfigurationPresetTableItems});

		if (this.presets) {
			this.device.presets = this.presets.map(preset => {
				return {
					presetNumber: preset.position,
					title: preset.name
				} as DevicePreset
			});
		}
	}

	private presetFieldIsInEditMode(preset: DeviceConfigurationPresetTableItems, field: VueBootstrapField): boolean {
		return (preset.editing && field.editable) || preset.creating;
	}

	private get currentlyCreating(): boolean {
		return this.presets.some(preset => preset.creating);
	}

	private allowedToSave(preset: DeviceConfigurationPresetTableItems): boolean {
		if (preset.position === null || preset.position === undefined || isNaN(preset.position))
		{
			return false;
		}
		else if (this.presets.filter(presetCheck =>
				parseInt(presetCheck.position.toString()) === parseInt((preset.position?.toString()) ?? this.nilNumber)
			).length > 1
		)
		{
			return false;
		}
		else if (preset.name?.length > 50)
		{
			return false;
		}

		return !isNaN(parseInt(preset.position.toString()));
	}

	private allowedToUpdate(preset: DeviceConfigurationPresetTableItems): boolean {
		return this.allowedToSave(preset) && preset.name !== this.device.presets.find(presetToFind => presetToFind.presetNumber === preset.position).title;
	}

	private presetValidityCSSClass(preset: DeviceConfigurationPresetTableItems) : string {
		if (preset.creating) {
			return this.presetPositionErrorClass(preset);
		}
		else {
			return "";
		}
	}

	private presetPositionErrorClass(preset: DeviceConfigurationPresetTableItems): string {
		if (preset.position == null)
		{
			return "";
		}

		if (isNaN(preset.position))
		{
			return "invalid";
		}

		if (this.presetPositionConflicts(preset.position))
		{
			return "invalid";
		}

		return isNaN(preset.position) ? "invalid" : "";
	}

	private presetTitleErrorClass(preset: DeviceConfigurationPresetTableItems): string {
		return preset.name?.length > 50 ? "invalid" : "error";
	}

	private presetPositionConflicts(position: number)
	{
		if (position == null)
		{
			return false;
		}

		let presetsBeingCreated = this.presets.filter(presetCheck =>
			presetCheck.creating === false
		);
		return presetsBeingCreated.some(presetBeingCreated =>
			parseInt((presetBeingCreated.position?.toString()) ?? this.nilNumber) === parseInt(position.toString()));
	}

	private cancelCreatePreset(preset: DeviceConfigurationPresetTableItems): void {
		preset.creating = false;
		this.presets.remove(preset);
	}

	private async createPreset(preset: DeviceConfigurationPresetTableItems): Promise<void> {
		await this.createOrUpdateCameraPreset({
			deviceId: this.device.deviceID,
			groupId: this.device.groupID ?? 0,
			position: parseInt(preset.position.toString()),
			name: preset.name
		} as DeviceConfigurationPresetPayload)

		this.device.presets.push({presetNumber: preset.position, title: preset.name} as DevicePreset);
		preset.creating = false;
	}

	private async updatePreset(preset: DeviceConfigurationPresetTableItems): Promise<void> {
		await this.createOrUpdateCameraPreset({
			deviceId: this.device.deviceID,
			groupId: this.device.groupID ?? 0,
			position: parseInt(preset.position.toString()),
			name: preset.name
		} as DeviceConfigurationPresetPayload)

		this.device.presets.find(presetToRemove => presetToRemove.presetNumber === preset.position).title = preset.name;
		preset.editing = false;
	}

	private editPreset(preset: DeviceConfigurationPresetTableItems): void {
		preset.editing = true;
	}

	private async deletePreset(preset: DeviceConfigurationPresetTableItems): Promise<void> {
		await this.deleteCameraPreset({
			deviceId: this.device.deviceID,
			groupId: this.device.groupID ?? 0,
			position: parseInt(preset.position.toString()),
			name: preset.name
		} as DeviceConfigurationPresetPayload)

		this.device.presets.removeWhere(presetToRemove => presetToRemove.presetNumber === preset.position);
		this.presets.remove(preset);
	}

	private cancelEditPreset(preset: DeviceConfigurationPresetTableItems): void {
		preset.editing = false;
		preset.name = this.device.presets.find(presetToFind => presetToFind.presetNumber === preset.position).title;
	}

	private addBlankPreset(): DeviceConfigurationPresetTableItems {
		let newPreset = {name: "New Preset", position: null, editing: false, creating: true} as DeviceConfigurationPresetTableItems;
		this.presets.push(newPreset);

		// return just for easier unit tests.
		return newPreset;
	}
}
