
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { DeviceTypeCounts, DeviceTypeIdentifier, ServerDetails, ServerType } from "@/store/devices/types";
import ChildDeviceTable from "@/components/device-setup/tables/ChildDeviceTable.vue";

const Devices = namespace("devices");

const childDeviceTitles: Record<string, any> = {
	[DeviceTypeIdentifier.Camera]: "Cameras",
	[DeviceTypeIdentifier.Audio]: "Audios",
	[DeviceTypeIdentifier.Output]: "Outputs",
	[DeviceTypeIdentifier.Door]: "Doors"
};

@Component({
	components: {
		"child-device-table": ChildDeviceTable
	}
})
export default class ChildDeviceTabWrapper extends Vue {
	$refs!: {
		childDeviceTable: ChildDeviceTable;
	};

	@Prop({ required: true, type: Number }) private deviceTypeIdentifier: number;
	@Prop({ required: true, default: true, type: Boolean }) private isForAllServers: boolean;
	@Prop({ required: true, default: false, type: Boolean }) private visible: boolean;
	@Prop({ default: false, type: Boolean }) private readonly: boolean;

	@Devices.State private currentServer: ServerDetails;
	@Devices.State private deviceTypeCounts: DeviceTypeCounts;
	@Devices.State private serverTypes: ServerType[];

	private tabLoaded: boolean = false;
	private updateDevices: boolean = false;
	private title: string = "";

	private created(): void {
		this.title = childDeviceTitles[this.deviceTypeIdentifier];
	}

	private get allowsDevices(): boolean {
		if (this.isForAllServers) {
			switch (this.deviceTypeIdentifier) {
				case DeviceTypeIdentifier.Camera:
					return this.serverTypes.some(st => st.numCameras !== 0);
				case DeviceTypeIdentifier.Audio:
					return this.serverTypes.some(st => st.numAudioInputs !== 0);
				case DeviceTypeIdentifier.Door:
				case DeviceTypeIdentifier.Output:
					return this.serverTypes.some(st => st.numRelays !== 0);
			}
		} else {
			const serverType: ServerType = this.serverTypes.find(
				st => st.serverTypeID === this.currentServer.serverTypeID
			);

			switch (this.deviceTypeIdentifier) {
				case DeviceTypeIdentifier.Camera:
					return serverType && serverType.numCameras !== 0;
				case DeviceTypeIdentifier.Audio:
					return serverType && serverType.numAudioInputs !== 0;
				case DeviceTypeIdentifier.Door:
				case DeviceTypeIdentifier.Output:
					return serverType && serverType.numRelays !== 0;
			}
		}

		return false;
	}

	private get deviceCount(): number {
		if (this.isForAllServers) {
			switch (this.deviceTypeIdentifier) {
				case DeviceTypeIdentifier.Camera:
					return this.deviceTypeCounts.cameras;
				case DeviceTypeIdentifier.Audio:
					return this.deviceTypeCounts.audios;
				case DeviceTypeIdentifier.Door:
					return this.deviceTypeCounts.doors;
				case DeviceTypeIdentifier.Output:
					return this.deviceTypeCounts.outputs;
			}
		} else {
			switch (this.deviceTypeIdentifier) {
				case DeviceTypeIdentifier.Camera:
					return this.currentServer.deviceTypeCounts.cameras;
				case DeviceTypeIdentifier.Audio:
					return this.currentServer.deviceTypeCounts.audios;
				case DeviceTypeIdentifier.Door:
					return this.currentServer.deviceTypeCounts.doors;
				case DeviceTypeIdentifier.Output:
					return this.currentServer.deviceTypeCounts.outputs;
			}
		}

		return 0;
	}

	private get showDeviceCounts(): boolean {
		if (
			(this.isForAllServers && this.deviceTypeCounts) ||
			(!this.isForAllServers && this.currentServer.deviceTypeCounts)
		) {
			return true;
		}

		return false;
	}

	private handleTabLoaded(): void {
		if (this.tabLoaded && this.$refs.childDeviceTable) {
			this.$refs.childDeviceTable.updateDevices();
		}
		this.tabLoaded = true;
	}

	private getTitleText(title: string): string {
		let text = "";
		if (!this.allowsDevices && !this.isForAllServers) {
			text = `${title} are not supported on this device`;
		}
		if (!this.allowsDevices && this.isForAllServers) {
			text = `There are Devices configured that support ${title}`;
		}
		return text;
	}
}
