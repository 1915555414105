
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter, namespace, State, Mutation } from "vuex-class";
import { debounce, get } from "lodash";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { CancelTokenSource } from "axios";

import { getDebouncePeriod } from "@/utils/debounce-helper";
import { getNewCancelTokenSource } from "@/utils/cancellableQueryHelper";
import { CancellableQuery, FeaturesList, UserGroup, UserPermissions, VueBootstrapField, VueBootstrapFieldTableSortEvent } from "@/store/types";
import {
	DeviceDetails,
	DeviceType,
	DeviceTypeIdentifier,
	GetDevicesByTypeParams,
	ServerDetails
} from "@/store/devices/types";
import { getNewDeviceDetailsTemplate } from "@/store/devices/templates";
import SureViewIcon from "@/components/SureViewIcon.vue";
import OpsLinkCameraImport from "@/components/OpsLinkCameraImport.vue";
import api from "@/services/api.service";

const Devices = namespace("devices");
const SiteMonitor = namespace("siteMonitor");

const childDeviceTitles: Record<string, any> = {
	[DeviceTypeIdentifier.Camera]: "Camera",
	[DeviceTypeIdentifier.Audio]: "Audio",
	[DeviceTypeIdentifier.Output]: "Output",
	[DeviceTypeIdentifier.Door]: "Door"
};

const outputSettingsMetaLabel = "Output";

@Component({
	components: {
		"sureview-icon": SureViewIcon,
		"vue-perfect-scrollbar": VuePerfectScrollbar,
		"ops-link-camera-import": OpsLinkCameraImport
	}
})
export default class ChildDeviceTable extends Vue {
	@Prop({ required: true, type: Number }) private deviceTypeId: number;
	@Prop({ required: true, default: false, type: Boolean }) private isForAllServers: boolean;
	@Prop({ required: true, default: false, type: Boolean }) private visible;
	@Prop({ default: false, type: Boolean }) private readonly;

	@Getter("getPermissions") private permissions: UserPermissions;
	@State private featuresList: FeaturesList;
	@State private UserGroups: UserGroup[];

	@Devices.Action private fetchDevicesForAllGroupsByType: (
		params: CancellableQuery<GetDevicesByTypeParams>
	) => Promise<void>;
	@Devices.Mutation private setCameraPreviewModalVisibility: (visible: boolean) => void;
	@Devices.Mutation private setCurrentDeviceTypeIdentifier: (deviceTypeIdentifier: DeviceTypeIdentifier) => void;
	@Devices.Mutation private setDeleteChildDeviceModalVisibility: (visible: boolean) => void;
	@Devices.Mutation private setDevice: (device: DeviceDetails) => void;
	@Devices.Mutation private setEditChildDeviceModalVisibility: (visible: boolean) => void;

	@Devices.State private currentServer: ServerDetails;
	@Devices.State private devices: DeviceDetails[];
	@Devices.State private totalDevices: number;
	@Devices.State private serversList: ServerDetails[];

	@SiteMonitor.Action private pollApplianceServerAndDeviceServerDetails: () => Promise<void>;
	@SiteMonitor.Action private stopPollingApplianceServerAndDeviceServerDetails: () => void;
	@SiteMonitor.Getter("getIsCameraEnabled") isCameraEnabled: (deviceId: number, test: string) => boolean;
	@SiteMonitor.Getter("getApplianceOfflineNotification") private applianceOfflineNotification: (deviceId: number) => string;

	private readonly deviceTypes = DeviceTypeIdentifier;

	private axiosCancelTokenSource: CancelTokenSource = null;
	private isLoading: boolean = false;
	private isTableBusy: boolean = false;
	private recordsPage: number = 1;
	private recordsPageSize: number = 25;
	private recordsPageSizeMax: number = 200;
	private recordsPageSizeUpdated: number = this.recordsPageSize;
	private sortBy: string = "title";
	private sortDesc: boolean = false;
	private searchTerm: string = "";
	private searchTermPersisted: string = "";
	private title: string = "";
	private isAddingWithOpsLink: boolean = false;
	private isCameraDetectionAvailable: boolean = false;

	private fields: VueBootstrapField[] = [
		{
			key: "title",
			label: "Title",
			sortable: true
		},
		{
			key: "groupID",
			label: "Area",
			formatter: groupID => {
				return this.getGroupTitle(groupID);
			},
			sortable: true
		},
		{
			key: "input1",
			label: "Input 1",
			sortable: true
		},
		{
			key: "input2",
			label: "Input 2",
			sortable: true
		},
		{
			key: "extraValue",
			label: "Extra Value",
			sortable: true,
			class: "extra-value-column"
		}
	];

	private async created(): Promise<void> {
		this.title = childDeviceTitles[this.deviceTypeId];

		if (this.isForAllServers) {
			this.addAllServersSpecificFields();
		}

		this.addChildSpecificFields();

		if ((this.hasEditPermission && !this.readonly) || this.tableIsForCameras) {
			this.addActions();
		}

		this.isTableBusy = true;

		await this.getDevices();

		this.isTableBusy = false;

		if (!this.isForAllServers) {
			//Records may have been added from outside this component, so we need to set the page to prevent needless API requests
			this.recordsPage = Math.floor(this.devices.length / this.recordsPageSize) + 1;
		}

		await this.checkCameraDetectionAvailability();
	}

	public async mounted(): Promise<void> {
		await this.pollApplianceServerAndDeviceServerDetails();
	}

	public beforeDestroy() {
		this.stopPollingApplianceServerAndDeviceServerDetails();
	}

	private get includeReferenceShots(): boolean {
		return this.tableIsForCameras && get(this.featuresList, ["Devices", "ShowReferenceShots"]);
	}

	private get tableIsForCameras(): boolean {
		return this.deviceTypeId === DeviceTypeIdentifier.Camera;
	}

	private addChildSpecificFields(): void {
		if (this.includeReferenceShots) {
			this.fields.splice(0, 0, {
				key: "preview",
				label: "Preview",
				sortable: false
			});
		}

		if (this.deviceTypeId === DeviceTypeIdentifier.Audio) {
			this.fields.push({
				key: "capabilities",
				label: "Capabilities",
				formatter: (value, key, item: DeviceDetails) => {
					return this.getAudioCapability(item);
				},
				sortByFormatted: true
			});
		}
	}

	private addAllServersSpecificFields(): void {
		this.fields.splice(1, 0, {
			key: "serverID",
			label: "Device",
			formatter: (value, key, item: DeviceDetails) => {
				return this.getDeviceTitle(item);
			},
			sortable: true
		});
	}

	private get hasEditPermission(): boolean {
		return this.permissions.isSystemAdmin || this.permissions.isAccountAdmin || this.permissions.canEditSiteSetup;
	}

	private get showCameraPreviewIcon(): boolean {
		return this.tableIsForCameras;
	}

	private addActions(): void {
		this.fields.push({ key: "actions", label: "Actions", sortable: false, class: "text-center" });
	}

	private async getDevices(): Promise<void> {
		this.isLoading = true;

		const sortBy = this.fields.find(f => f.key === this.sortBy)?.label.replace(/\s/g,'');

		const queryParams: GetDevicesByTypeParams = {
			deviceTypeId: this.deviceTypeId,
			page: this.recordsPage,
			pageSize: this.recordsPageSize,
			sortBy: sortBy || this.sortBy,
			sortDesc: this.sortDesc,
			searchTerm: this.searchTerm
		};

		if (this.includeReferenceShots) {
			queryParams.includeReferenceShots = true;
		}

		if (!this.isForAllServers) {
			queryParams.serverId = this.currentServer.serverID;
		}

		if (this.queryIsForRelays) {
			this.adjustQueryForRelays(queryParams);
		}

		this.axiosCancelTokenSource = getNewCancelTokenSource(this.axiosCancelTokenSource, true);

		const cancellableQuery: CancellableQuery<GetDevicesByTypeParams> = {
			params: queryParams,
			cancelTokenSource: this.axiosCancelTokenSource
		};

		try {
			await this.fetchDevicesForAllGroupsByType(cancellableQuery);
		}
		catch (e) {
			this.$notify({
				type: "error",
				title: "Error loading devices",
				text: `Unable to load devices`
			});
			console.error(e);
		}
		finally {
			this.isLoading = false;
		}
	}

	private get queryIsForRelays(): boolean {
		return this.deviceTypeId === DeviceTypeIdentifier.Output || this.deviceTypeId === DeviceTypeIdentifier.Door;
	}

	private adjustQueryForRelays(queryParams: GetDevicesByTypeParams): void {
		queryParams.selectOutputs = queryParams.deviceTypeId === DeviceTypeIdentifier.Output;
		queryParams.deviceTypeId = DeviceType.Relay;
	}

	private getGroupTitle(groupID: number): string {
		return this.UserGroups.find(ug => ug.groupID == groupID)?.title;
	}

	private getAudioCapability(device: DeviceDetails): string {
		if (device.audioSettings?.transmitEnabled && device.audioSettings?.receiveEnabled) {
			return "2-Way Audio";
		}

		if (device.audioSettings?.transmitEnabled) {
			return "Transmit";
		}

		if (device.audioSettings?.receiveEnabled) {
			return "Receive";
		}

		return "";
	}

	private getDeviceTitle(device: DeviceDetails): string {
		const server = this.serversList.find(s => s.serverID == device.serverID);

		if (server) {
			return server.title;
		}

		return device.serverTitle;
	}

	private handleSearchInput = debounce(async (searchTerm: string): Promise<void> => {
		await this.performSearch(searchTerm);
	}, getDebouncePeriod());

	private async performSearch(searchTerm: string): Promise<void> {
		this.searchTerm = searchTerm;
		await this.getDevices();
	}

	private handleAddDevice(): void {
		// this.setIsLoading(true);

		const newDevice = getNewDeviceDetailsTemplate();
		newDevice.deviceTypeID = this.deviceTypeId;

		if (this.deviceTypeId === DeviceTypeIdentifier.Door || this.deviceTypeId === DeviceTypeIdentifier.Output) {
			newDevice.deviceTypeID = DeviceType.Relay;
		}

		if (this.currentServer) {
			newDevice.serverID = this.currentServer.serverID;
			newDevice.groupID = this.currentServer.groupID;
		}

		this.setDevice(newDevice);
		this.setCurrentDeviceTypeIdentifier(this.deviceTypeId);
		this.setEditChildDeviceModalVisibility(true);
	}

	private handleEditClick(device: DeviceDetails): void {
		// this.setIsLoading(true);
		this.setDevice(device);
		this.setCurrentDeviceTypeIdentifier(this.deviceTypeId);
		this.setEditChildDeviceModalVisibility(true);
	}

	private handlePreviewCameraClick(device: DeviceDetails): void {
		this.setDevice(device);
		this.setCameraPreviewModalVisibility(true);
	}

	private handleDeleteClick(device: DeviceDetails): void {
		this.setDevice(device);
		this.setCurrentDeviceTypeIdentifier(this.deviceTypeId);
		this.setDeleteChildDeviceModalVisibility(true);
	}

	private getPreviewImageSource(previewBase64: string): string {
		return `data:image;base64,${previewBase64}`;
	}

	private async onPageClick(page: number) {
		if (!this.visible || this.isLoading) {
			return;
		}

		this.recordsPage = page || this.recordsPage;

		await this.getDevices();
	}

	private async onRecordsPerPageClick() {
		let pageSize = +this.recordsPageSizeUpdated;

		if (pageSize) {
			if (pageSize > this.recordsPageSizeMax) {
				pageSize = this.recordsPageSizeMax;
			}
			if (pageSize !== this.recordsPageSize) {
				this.recordsPage = 1;
			}
			this.recordsPageSize = pageSize;
		}
		this.recordsPageSizeUpdated = this.recordsPageSize;
		this.onPageClick(this.recordsPage);
	}

	public async updateDevices() {
		this.isTableBusy = true;

		try
		{
			await this.getDevices();
		}
		finally
		{
			this.isTableBusy = false;
		}
	}

	private async checkCameraDetectionAvailability() {
		let isFeatureEnabled = get(this.featuresList, ["Devices", "Appliances", "CameraDetection"]);
		if (!isFeatureEnabled) {
			return false;
		}

		let availableDetectors = await api.getApplianceList();
		this.isCameraDetectionAvailable = availableDetectors.length !== 0;
	}

	private async onSortChange(event: VueBootstrapFieldTableSortEvent): Promise<void> {
		if (!this.visible || this.isLoading) {
			return;
		}

		this.sortBy = event.sortBy || this.sortBy;
		this.sortDesc = event.sortDesc;

		await this.getDevices();
	}

	private hasEditPermissionToDevice(device: DeviceDetails): boolean {
		if (!device || !device.groupSyncId) {
			return true;
		}
		return device && device.groupSyncId && (this.permissions.canOverrideGroupSync || this.permissions.isSystemAdmin);
	}
}
