
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { VueBootstrapField, FeaturesList } from "@/store/types";
import {
	ServerDetails,
	ServerType,
	DeviceConfiguration,
	DeviceConfigurationErrors,
	ServerConfiguration,
	DeviceConfigurationSelections,
	DeviceConfigurationCamera,
	DeviceConfigurationCameraSelection,
	DeviceConfigurationAlarm,
	DeviceConfigurationAlarmSelection,
	DeviceConfigurationAudio,
	DeviceConfigurationAudioSelection,
	DeviceConfigurationRelay,
	DeviceConfigurationRelaySelection,
	DeviceDetails,
	DeviceResponseRecordsRetrievalStatus,
	FetchConfigForServerParams,
} from "@/store/devices/types";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import SureViewIcon from "../SureViewIcon.vue";
import { DeviceService } from "@sureview/camera";
import { EventDetails } from "@/store/site-monitor/types";
import { SubscriptionDto } from "@/store/subscription/types";
import { get } from "lodash";
import { SessionResource } from "@/store/sessions/types";

enum GetConfigStatus {
	Inactive = "inactive",
	Connecting = "connecting",
	Connected = "connected",
	Processing = "processing",
	Done = "done",
	Error = "error",
}

const Devices = namespace("devices");
const SiteMonitor = namespace("siteMonitor");
const Sessions = namespace("sessions");
const Subscription = namespace("subscription");

@Component({
	components: { "vue-perfect-scrollbar": VuePerfectScrollbar, "sureview-icon": SureViewIcon },
})
export default class GetConfig extends Vue {
	private fields: VueBootstrapField[] = [
		{ key: "title", label: "Title", sortable: false },
		{ key: "serverTypeID", label: "Server Type ID", sortable: false },
		{ key: "groupID", label: "Area", sortable: false },
		{ key: "host", label: "Host", sortable: false },
		{ key: "port", label: "Port", sortable: false },
		{ key: "isRaiseIndividual", label: "IsRaiseIndividual", sortable: false, class: "centered" },
		{ key: "isRaiseGrouped", label: "IsRaiseGrouped", sortable: false, class: "centered" },
		{ key: "actions", label: "Actions", sortable: false },
	];

	@Devices.Action private addDevicesForServer: (serverConfig: ServerConfiguration) => Promise<void>;
	@Devices.Action private fetchServerConfig: (params: FetchConfigForServerParams) => Promise<void>;

	@Devices.State private currentServer: ServerDetails;
	@Devices.State("device") private deviceFromStore: DeviceDetails;
	@Devices.State private serverTypes: ServerType[];
	@Devices.State gettingConfig: boolean;
	@Devices.State settingConfig: boolean;
	@Devices.State serverConfig: DeviceConfiguration[];
	@Devices.State deviceConfigurationErrors: DeviceConfigurationErrors;
	@Devices.State private deviceResponseRecordsRetrievalStatus: DeviceResponseRecordsRetrievalStatus;

	@Devices.Mutation private setSettingConfig: (settingConfig: boolean) => void;
	@Devices.Mutation private setExtraValue: (extraValue: string) => void;
	@Devices.Mutation setServerModalVisible: (IsVisible: boolean) => void;
	@Devices.Mutation private setDeviceConfigErrors: (errors: DeviceConfigurationErrors) => void;
	
	@Prop(Number) eventid?: number;

	@Getter("getFeaturesList") featuresList: FeaturesList;

	@Sessions.Getter getSession: any;
	@Sessions.Action updateSession: any;

	@SiteMonitor.Getter getEventDetails: EventDetails;

	@Subscription.State subscription: SubscriptionDto;
	@Subscription.Action fetchSubscription: () => Promise<void>;

	created() {
		this.updateSession({ resourceId: 3 });
		this.selectedServer = { ...this.currentServer };
	}

	async mounted() {
		this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
			switch (collapseId) {
				case "accordion-cameras": {
					this.cameraAccordionTabOpened = isJustShown;
					break;
				}
				case "accordion-alarms": {
					this.alarmAccordionTabOpened = isJustShown;
					break;
				}
				case "accordion-audios": {
					this.audioAccordionTabOpened = isJustShown;
					break;
				}
				case "accordion-doors": {
					this.doorAccordionTabOpened = isJustShown;
					break;
				}
				case "accordion-outputs": {
					this.outputAccordionTabOpened = isJustShown;
					break;
				}
			}
		});
	}

	private updateExtraValue(ev: string) {
		this.setExtraValue(ev);
	}

	resetModal() {}

	private selectedServer: ServerDetails;
	private getConfigStatus: GetConfigStatus = GetConfigStatus.Inactive;
	private progress = 0;
	private camerasPage: number = 1;
	private camerasPageSize: number = 1000;

	private alarmsPage: number = 1;
	private alarmsPageSize: number = 1000;

	private audiosPage: number = 1;
	private audiosPageSize: number = 1000;

	private doorsPage: number = 1;
	private doorsPageSize: number = 1000;

	private outputsPage: number = 1;
	private outputsPageSize: number = 1000;

	private getConfigHasBeenRan = false;
	private displayFilteredRecords: boolean = false;
	private cameraTableIsBusy: boolean = false;
	private loadingMoreCameras: boolean = false;

	private alarmTableIsBusy: boolean = false;
	private loadingMoreAlarms: boolean = false;

	private audioTableIsBusy: boolean = false;
	private loadingMoreAudios: boolean = false;

	private doorTableIsBusy: boolean = false;
	private loadingMoreDoors: boolean = false;

	private outputTableIsBusy: boolean = false;
	private loadingMoreOutputs: boolean = false;

	private allCamerasSelected: boolean = true;
	private allAudiosSelected: boolean = true;
	private allAlarmsSelected: boolean = true;
	private allOutputsSelected: boolean = true;
	private allDoorsSelected: boolean = true;

	private noCamerasSelected: boolean = false;
	private noAudiosSelected: boolean = false;
	private noAlarmsSelected: boolean = false;
	private noOutputsSelected: boolean = false;
	private noDoorsSelected: boolean = false;

	private camerasTotal = 0;
	private alarmsTotal = 0;
	private audiosTotal = 0;
	private doorsTotal = 0;
	private outputsTotal = 0;

	private cameraAccordionTabOpened = false;
	private alarmAccordionTabOpened = false;
	private audioAccordionTabOpened = false;
	private doorAccordionTabOpened = false;
	private outputAccordionTabOpened = false;

	private serverConfigDataList: DeviceConfiguration[];
	private serverConfigData: DeviceConfigurationSelections;
	private serverConfigDataDoors: DeviceConfigurationRelay[];
	private serverConfigDataOutputs: DeviceConfigurationRelay[];

	private cameraUsageDifference: number = 0;

	private get extraValueLabel(): string {
		return `${this.extraValuePlaceholder}:`;
	}

	private get extraValuePlaceholder(): string {
		const serverType = this.selectedServerType;

		if (serverType && serverType.extraValueName) {
			return serverType.extraValueName;
		}

		return "Extra Value";
	}

	private get selectedServerType(): ServerType {
		return this.serverTypes.find((st) => st.serverTypeID === this.selectedServer.serverTypeID);
	}

	private get allCamerasInCurrentQueryAreRetrieved(): boolean {
		return this.deviceResponseRecordsRetrievalStatus.allCamerasRetrieved;
	}

	private get isBillingEnabled(): boolean {
		return get(this.featuresList, ["Billing"]);
	}

    private get isCameraSubscriptionValid(): boolean {
        if(this.subscription && this.subscription.limitedSubscription) {
            if((this.subscription.cameras + this.cameraUsageDifference) > this.subscription.camerasLimit) {
                return false;
            }
        }
        return true;
    }

	private handleCameraScrollBarReachEnd() {
		if (
			this.cameraTableIsBusy ||
			this.allCamerasInCurrentQueryAreRetrieved ||
			this.loadingMoreCameras ||
			!this.cameraAccordionTabOpened
		) {
			return false;
		}

		this.loadingMoreCameras = true;
		this.camerasPage++;
		this.getCamerasForScroll();
		this.loadingMoreCameras = false;
	}

	private getCamerasForScroll() {
		this.cameraTableIsBusy = true;

		try
		{
			this.getCameras();
		}
		catch (e) {
			this.$notify({
				type: "error",
				title: "Error loading cameras",
				text: `Unable to load cameras`
			});
			console.error(e);
		}
		finally {
			this.cameraTableIsBusy = false;
		}
	}

	private get allAlarmsInCurrentQueryAreRetrieved(): boolean {
		return this.deviceResponseRecordsRetrievalStatus.allAlarmsRetrieved;
	}

	private handleAlarmScrollBarReachEnd() {
		if (
			this.alarmTableIsBusy ||
			this.allAlarmsInCurrentQueryAreRetrieved ||
			this.loadingMoreAlarms ||
			!this.alarmAccordionTabOpened
		) {
			return false;
		}

		this.loadingMoreAlarms = true;
		this.alarmsPage++;
		this.getAlarmsForScroll();
		this.loadingMoreAlarms = false;
	}

	private getAlarmsForScroll() {
		this.alarmTableIsBusy = true;

		try
		{
			this.getAlarms();
		}
		catch (e) {
			this.$notify({
				type: "error",
				title: "Error loading alarms",
				text: `Unable to load alarms`
			});
			console.error(e);
		}
		finally {
			this.alarmTableIsBusy = false;
		}
	}

	private get allAudiosInCurrentQueryAreRetrieved(): boolean {
		return this.deviceResponseRecordsRetrievalStatus.allAudiosRetrieved;
	}

	private handleAudioScrollBarReachEnd() {
		if (
			this.audioTableIsBusy ||
			this.allAudiosInCurrentQueryAreRetrieved ||
			this.loadingMoreAudios ||
			!this.audioAccordionTabOpened
		) {
			return false;
		}

		this.loadingMoreAudios = true;
		this.audiosPage++;
		this.getAudiosForScroll();
		this.loadingMoreAudios = false;
	}

	private getAudiosForScroll() {
		this.audioTableIsBusy = true;

		try
		{
			this.getAudios();
		}
		catch (e) {
			this.$notify({
				type: "error",
				title: "Error loading audios",
				text: `Unable to load audios`
			});
			console.error(e);
		}
		finally {
			this.audioTableIsBusy = false;
		}
	}

	private get allDoorsInCurrentQueryAreRetrieved(): boolean {
		return this.deviceResponseRecordsRetrievalStatus.allDoorsRetrieved;
	}

	private handleDoorScrollBarReachEnd() {
		if (
			this.doorTableIsBusy ||
			this.allDoorsInCurrentQueryAreRetrieved ||
			this.loadingMoreDoors ||
			!this.doorAccordionTabOpened
		) {
			return false;
		}

		this.loadingMoreDoors = true;
		this.doorsPage++;
		this.getDoorsForScroll();
		this.loadingMoreDoors = false;
	}

	private getDoorsForScroll() {
		this.doorTableIsBusy = true;

		try
		{
			this.getDoors();
		}
		catch (e) {
			this.$notify({
				type: "error",
				title: "Error loading doors",
				text: `Unable to load doors`
			});
			console.error(e);
		}
		finally {
			this.doorTableIsBusy = false;
		}
	}

	private get allOutputsInCurrentQueryAreRetrieved(): boolean {
		return this.deviceResponseRecordsRetrievalStatus.allFilteredOutputsRetrieved;
	}

	private handleOutputScrollBarReachEnd() {
		if (
			this.outputTableIsBusy ||
			this.allOutputsInCurrentQueryAreRetrieved ||
			this.loadingMoreOutputs ||
			!this.outputAccordionTabOpened
		) {
			return false;
		}

		this.loadingMoreOutputs = true;
		this.outputsPage++;
		this.getOutputsForScroll();
		this.loadingMoreOutputs = false;
	}

	private getOutputsForScroll() {
		this.outputTableIsBusy = true;

		try
		{
			this.getOutputs();
		}
		catch (e) {
			this.$notify({
				type: "error",
				title: "Error loading outputs",
				text: `Unable to load outputs`
			});
			console.error(e);
		}
		finally {
			this.outputTableIsBusy = false;
		}
	}

	private populateLocalData(serverConfigAlreadySet: boolean) {
		if (serverConfigAlreadySet == null || !serverConfigAlreadySet) {
			this.serverConfigDataList = this.serverConfig;
		}

		if (this.serverConfigDataList && this.serverConfigDataList[0]) {
			if (this.serverConfigDataList[0].cameras != null && this.serverConfigDataList[0].cameras.length > 0) {
				this.camerasTotal = this.serverConfigDataList[0].cameras.length;
			}

			if (this.serverConfigDataList[0].alarms != null && this.serverConfigDataList[0].alarms.length > 0) {
				this.alarmsTotal = this.serverConfigDataList[0].alarms.length;
			}

			if (this.serverConfigDataList[0].audios != null && this.serverConfigDataList[0].audios.length > 0) {
				this.audiosTotal = this.serverConfigDataList[0].audios.length;
			}

			if (this.serverConfigDataList[0].relays != null && this.serverConfigDataList[0].relays.length > 0) {
				this.serverConfigDataOutputs = this.serverConfigDataList[0].relays.filter(function (obj) {
					return obj.type == 0;
				});
				this.outputsTotal = this.serverConfigDataOutputs != null ? this.serverConfigDataOutputs.length : 0;
				this.serverConfigDataDoors = this.serverConfigDataList[0].relays.filter(function (obj) {
					return obj.type != 0;
				});
				this.doorsTotal = this.serverConfigDataDoors != null ? this.serverConfigDataDoors.length : 0;
			}
		}

		//Map the config data retrieved to also include selected property in the arrays (set to true by default)
		if (this.serverConfigDataList && this.serverConfigDataList.length) {
			this.serverConfigData = {
				name: this.serverConfigDataList[0].name,
				extraValue: this.serverConfigDataList[0].extraValue,
				host: this.serverConfigDataList[0].host,
				port: this.serverConfigDataList[0].port,
				username: this.serverConfigDataList[0].username,
				password: this.serverConfigDataList[0].password,
				syncIdentifier: this.serverConfigDataList[0].syncIdentifier,
				alarms: null,
				cameras: null,
				audios: null,
				doors: null,
				outputs: null,
				users: this.serverConfigDataList[0].users,
			};

			this.getCameras();
			this.getAlarms();
			this.getAudios();
			this.getDoors();
			this.getOutputs();
		}

		this.getConfigStatus = GetConfigStatus.Done;
		this.getConfigHasBeenRan = true;
		this.progress = 100;
	}

	private getDeviceService(): DeviceService {
		let deviceService = new DeviceService(window.location.origin, this.deviceFromStore?.deviceServerEndpoint);
		deviceService.authSessionID = this.getSession(SessionResource.DeviceServiceSession);
		return deviceService;
	}

	private async doGetConfig() {
		this.setDeviceConfigErrors(null);

		let deviceService = this.getDeviceService();
		this.getConfigStatus = GetConfigStatus.Connecting;
		this.progress = 0;

		await Promise.all([this.fetchServerConfig({ server: this.currentServer, serverType: this.selectedServerType, auth: deviceService.authSessionID })]).then(
			(values) => {
				this.populateLocalData(false);
			}
		);
	}

	private async getCameras() {
		if (
			this.serverConfigData == null ||
			this.serverConfigDataList.length == 0 ||
			this.serverConfigDataList[0] == null
		) {
			return;
		}

		if (this.serverConfigDataList[0].cameras == null) {
			this.serverConfigDataList[0].cameras = [];
		}

		//read page number and page size to work out start and end points
		let startIndex = (this.camerasPage - 1) * this.camerasPageSize;

		if (startIndex >= this.serverConfigDataList[0].cameras.length) {
			//all cameras loaded
			this.deviceResponseRecordsRetrievalStatus.allCamerasRetrieved = true;
			return;
		}

		let totalWillbeReached = false;
		let endIndex = startIndex + this.camerasPageSize;

		if (endIndex >= this.serverConfigDataList[0].cameras.length) {
			totalWillbeReached = true;
			endIndex = this.serverConfigDataList[0].cameras.length;
		}

		let camerasWithSelect: DeviceConfigurationCameraSelection[] = [];
		let selected: boolean = !this.noCamerasSelected;

		for (let i = startIndex; i < endIndex; i++) {
			let newCamera: DeviceConfigurationCameraSelection = {
				name: this.serverConfigDataList[0].cameras[i].name,
				input1: this.serverConfigDataList[0].cameras[i].input1,
				hasPtz: this.serverConfigDataList[0].cameras[i].hasPtz,
				presets: this.serverConfigDataList[0].cameras[i].presets,
				referenceImage: this.serverConfigDataList[0].cameras[i].referenceImage,
				syncIdentifier: this.serverConfigDataList[0].cameras[i].syncIdentifier,
				extraValue: this.serverConfigDataList[0].cameras[i].extraValue,
				features: this.serverConfigDataList[0].cameras[i].features,
				latLong: this.serverConfigDataList[0].cameras[i].latLong,
				selected: selected,
			};
			camerasWithSelect.push(newCamera);
		}

		if (this.serverConfigData.cameras == null) {
			this.serverConfigData.cameras = [];
		}

		Array.prototype.push.apply(this.serverConfigData.cameras, camerasWithSelect);

		if (totalWillbeReached) {
			this.deviceResponseRecordsRetrievalStatus.allCamerasRetrieved = true;
		}
		
		this.refreshCameraUsageDifference();
	}

	private async getAlarms() {
		if (
			this.serverConfigData == null ||
			this.serverConfigDataList.length == 0 ||
			this.serverConfigDataList[0] == null
		) {
			return;
		}

		if (this.serverConfigDataList[0].alarms == null) {
			this.serverConfigDataList[0].alarms = [];
		}

		//read page number and page size to work out start and end points
		let startIndex = (this.alarmsPage - 1) * this.alarmsPageSize;

		if (startIndex >= this.serverConfigDataList[0].alarms.length) {
			//all alarms loaded
			this.deviceResponseRecordsRetrievalStatus.allAlarmsRetrieved = true;
			return;
		}

		let totalWillbeReached = false;
		let endIndex = startIndex + this.alarmsPageSize;

		if (endIndex >= this.serverConfigDataList[0].alarms.length) {
			totalWillbeReached = true;
			endIndex = this.serverConfigDataList[0].alarms.length;
		}

		let alarmsWithSelect: DeviceConfigurationAlarmSelection[] = [];
		let selected: boolean = !this.noAlarmsSelected;

		for (let i = startIndex; i < endIndex; i++) {
			let newAlarm: DeviceConfigurationAlarmSelection = {
				title: this.serverConfigDataList[0].alarms[i].title,
				input1: this.serverConfigDataList[0].alarms[i].input1,
				input2: this.serverConfigDataList[0].alarms[i].input2,
				eventType: this.serverConfigDataList[0].alarms[i].eventType,
				syncIdentifier: this.serverConfigDataList[0].alarms[i].syncIdentifier,
				extraValue: this.serverConfigDataList[0].alarms[i].extraValue,
				linkedCameraInput: this.serverConfigDataList[0].alarms[i].linkedCameraInput,
				highPriority: this.serverConfigDataList[0].alarms[i].highPriority,
				selected: selected,
			};
			alarmsWithSelect.push(newAlarm);
		}

		if (this.serverConfigData.alarms == null) {
			this.serverConfigData.alarms = [];
		}

		Array.prototype.push.apply(this.serverConfigData.alarms, alarmsWithSelect);

		if (totalWillbeReached) {
			this.deviceResponseRecordsRetrievalStatus.allAlarmsRetrieved = true;
		}
	}

	private async getAudios() {
		if (
			this.serverConfigData == null ||
			this.serverConfigDataList.length == 0 ||
			this.serverConfigDataList[0] == null
		) {
			return;
		}

		if (this.serverConfigDataList[0].audios == null) {
			this.serverConfigDataList[0].audios = [];
		}

		//read page number and page size to work out start and end points
		let startIndex = (this.audiosPage - 1) * this.audiosPageSize;

		if (startIndex >= this.serverConfigDataList[0].audios.length) {
			//all audios loaded
			this.deviceResponseRecordsRetrievalStatus.allAudiosRetrieved = true;
			return;
		}

		let totalWillbeReached = false;
		let endIndex = startIndex + this.audiosPageSize;

		if (endIndex >= this.serverConfigDataList[0].audios.length) {
			totalWillbeReached = true;
			endIndex = this.serverConfigDataList[0].audios.length;
		}

		let audiosWithSelect: DeviceConfigurationAudioSelection[] = [];
		let selected: boolean = !this.noAudiosSelected;

		for (let i = startIndex; i < endIndex; i++) {
			let newAudio: DeviceConfigurationAudioSelection = {
				name: this.serverConfigDataList[0].audios[i].name,
				input1: this.serverConfigDataList[0].audios[i].input1,
				type: this.serverConfigDataList[0].audios[i].type,
				syncIdentifier: this.serverConfigDataList[0].audios[i].syncIdentifier,
				extraValue: this.serverConfigDataList[0].audios[i].extraValue,
				features: this.serverConfigDataList[0].audios[i].features,
				latLong: this.serverConfigDataList[0].audios[i].latLong,
				selected: selected,
			};
			audiosWithSelect.push(newAudio);
		}

		if (this.serverConfigData.audios == null) {
			this.serverConfigData.audios = [];
		}

		Array.prototype.push.apply(this.serverConfigData.audios, audiosWithSelect);

		if (totalWillbeReached) {
			this.deviceResponseRecordsRetrievalStatus.allAudiosRetrieved = true;
		}
	}

	private async getDoors() {
		if (this.serverConfigData == null || this.serverConfigDataList.length == 0) {
			return;
		}

		if (this.serverConfigDataDoors == null) {
			this.serverConfigDataDoors = [];
		}

		//read page number and page size to work out start and end points
		let startIndex = (this.doorsPage - 1) * this.doorsPageSize;

		if (startIndex >= this.serverConfigDataDoors.length) {
			//all doors loaded
			this.deviceResponseRecordsRetrievalStatus.allDoorsRetrieved = true;
			return;
		}

		let totalWillbeReached = false;
		let endIndex = startIndex + this.doorsPageSize;

		if (endIndex >= this.serverConfigDataDoors.length) {
			totalWillbeReached = true;
			endIndex = this.serverConfigDataDoors.length;
		}

		let doorsWithSelect: DeviceConfigurationRelaySelection[] = [];
		let selected: boolean = !this.noDoorsSelected;

		for (let i = startIndex; i < endIndex; i++) {
			let newRelay: DeviceConfigurationRelaySelection = {
				name: this.serverConfigDataDoors[i].name,
				input1: this.serverConfigDataDoors[i].input1,
				input2: this.serverConfigDataDoors[i].input2,
				type: this.serverConfigDataDoors[i].type,
				syncIdentifier: this.serverConfigDataDoors[i].syncIdentifier,
				extraValue: this.serverConfigDataDoors[i].extraValue,
				features: this.serverConfigDataDoors[i].features,
				latLong: this.serverConfigDataDoors[i].latLong,
				selected: selected,
			};
			doorsWithSelect.push(newRelay);
		}

		if (this.serverConfigData.doors == null) {
			this.serverConfigData.doors = [];
		}

		Array.prototype.push.apply(this.serverConfigData.doors, doorsWithSelect);

		if (totalWillbeReached) {
			this.deviceResponseRecordsRetrievalStatus.allDoorsRetrieved = true;
		}
	}

	private async getOutputs() {
		if (this.serverConfigData == null || this.serverConfigDataList.length == 0) {
			return;
		}

		if (this.serverConfigDataOutputs == null) {
			this.serverConfigDataOutputs = [];
		}

		//read page number and page size to work out start and end points
		let startIndex = (this.outputsPage - 1) * this.outputsPage;

		if (startIndex >= this.serverConfigDataOutputs.length) {
			//all outputs loaded
			this.deviceResponseRecordsRetrievalStatus.allFilteredOutputsRetrieved = true;
			return;
		}

		let totalWillbeReached = false;
		let endIndex = startIndex + this.outputsPageSize;

		if (endIndex >= this.serverConfigDataOutputs.length) {
			totalWillbeReached = true;
			endIndex = this.serverConfigDataOutputs.length;
		}

		let outputsWithSelect: DeviceConfigurationRelaySelection[] = [];
		let selected: boolean = !this.noOutputsSelected;

		for (let i = startIndex; i < endIndex; i++) {
			let newRelay: DeviceConfigurationRelaySelection = {
				name: this.serverConfigDataOutputs[i].name,
				input1: this.serverConfigDataOutputs[i].input1,
				input2: this.serverConfigDataOutputs[i].input2,
				type: this.serverConfigDataOutputs[i].type,
				syncIdentifier: this.serverConfigDataOutputs[i].syncIdentifier,
				extraValue: this.serverConfigDataOutputs[i].extraValue,
				features: this.serverConfigDataOutputs[i].features,
				latLong: this.serverConfigDataOutputs[i].latLong,
				selected: selected,
			};
			outputsWithSelect.push(newRelay);
		}

		if (this.serverConfigData.outputs == null) {
			this.serverConfigData.outputs = [];
		}

		Array.prototype.push.apply(this.serverConfigData.outputs, outputsWithSelect);

		if (totalWillbeReached) {
			this.deviceResponseRecordsRetrievalStatus.allFilteredOutputsRetrieved = true;
		}
	}

	private get deviceFields() {
		let fields = [
			{ key: "selected", label: "Selected", sortable: false },
			{ key: "input1", label: "Input1", sortable: false },
			{ key: "name", label: "Name", sortable: false },
		];

		return fields;
	}

	private get responseFields() {
		let fields = [
			{ key: "selected", label: "Selected", sortable: false },
			{ key: "input1", label: "Input1", sortable: false },
			{ key: "title", label: "Name", sortable: false },
		];

		return fields;
	}

	private get deviceErrorFields() {
		let fields = [
			{ key: "name", label: "Name", sortable: false },
			{ key: "input1", label: "Input1", sortable: false },
			{ key: "error", label: "Error", sortable: false },
		];

		return fields;
	}

	private get responseErrorFields() {
		let fields = [
			{ key: "title", label: "Name", sortable: false },
			{ key: "input1", label: "Input1", sortable: false },
		];

		return fields;
	}

	private cancel() {
		this.progress = 0;
		this.getConfigHasBeenRan = false;
		this.getConfigStatus = GetConfigStatus.Inactive;
	}

	private async addConfig(serverConfig: ServerConfiguration): Promise<void> {
		await Promise.all([this.addDevicesForServer(serverConfig)]).then((response) => {
			this.progress = 100;
		});
	}

	private save() {
		this.progress = 0;
		this.setSettingConfig(true);

		let selectedCameras: DeviceConfigurationCamera[] = [];
		if (this.serverConfigData.cameras != null) {
			selectedCameras = this.serverConfigData.cameras.filter(function (obj) {
				return obj.selected === true;
			});
		}

		let selectedAlarms: DeviceConfigurationAlarm[] = [];
		if (this.serverConfigData.alarms != null) {
			selectedAlarms = this.serverConfigData.alarms.filter(function (obj) {
				return obj.selected === true;
			});
		}

		let selectedAudios: DeviceConfigurationAudio[] = [];
		if (this.serverConfigData.audios != null) {
			selectedAudios = this.serverConfigData.audios.filter(function (obj) {
				return obj.selected === true;
			});
		}

		let selectedDoors: DeviceConfigurationRelay[] = [];
		if (this.serverConfigData.doors != null) {
			selectedDoors = this.serverConfigData.doors.filter(function (obj) {
				return obj.selected === true;
			});
		}

		let selectedOutputs: DeviceConfigurationRelay[] = [];
		if (this.serverConfigData.outputs != null) {
			selectedOutputs = this.serverConfigData.outputs.filter(function (obj) {
				return obj.selected === true;
			});
		}

		let selectedRelays: DeviceConfigurationRelay[] = [];

		//Add unloaded (through pagination) devices to the lists
		if (this.serverConfigDataList[0].cameras != null) {
			if (this.serverConfigData.cameras == null) {
				this.serverConfigData.cameras = [];
			}
			if (this.serverConfigData.cameras.length < this.serverConfigDataList[0].cameras.length) {
				Array.prototype.push.apply(
					selectedCameras,
					this.serverConfigDataList[0].cameras.slice(
						this.serverConfigData.cameras.length,
						this.serverConfigDataList[0].cameras.length
					)
				);
			}
		}

		if (this.serverConfigDataList[0].alarms != null) {
			if (this.serverConfigData.alarms == null) {
				this.serverConfigData.alarms = [];
			}
			if (this.serverConfigData.alarms.length < this.serverConfigDataList[0].alarms.length) {
				Array.prototype.push.apply(
					selectedAlarms,
					this.serverConfigDataList[0].alarms.slice(
						this.serverConfigData.alarms.length,
						this.serverConfigDataList[0].alarms.length
					)
				);
			}
		}

		if (this.serverConfigDataList[0].audios != null) {
			if (this.serverConfigData.audios == null) {
				this.serverConfigData.audios = [];
			}
			if (this.serverConfigData.audios.length < this.serverConfigDataList[0].audios.length) {
				Array.prototype.push.apply(
					selectedAudios,
					this.serverConfigDataList[0].audios.slice(
						this.serverConfigData.audios.length,
						this.serverConfigDataList[0].audios.length
					)
				);
			}
		}

		if (this.serverConfigDataList[0].relays != null) {
			if (this.serverConfigData.doors == null) {
				this.serverConfigData.doors = [];
			}
			if (this.serverConfigData.doors.length <= this.serverConfigDataList[0].relays.filter(x => x.type == 1).length) {
				Array.prototype.push.apply(
					selectedDoors,
					this.serverConfigDataDoors.slice(this.serverConfigData.doors.length, this.serverConfigDataDoors.length)
				);
				Array.prototype.push.apply(selectedRelays, selectedDoors);
			}
			if (this.serverConfigData.outputs == null) {
				this.serverConfigData.outputs = [];
			}
			if (this.serverConfigData.outputs.length <= this.serverConfigDataList[0].relays.filter(x => x.type == 0).length) {
				Array.prototype.push.apply(
					selectedOutputs,
					this.serverConfigDataOutputs.slice(this.serverConfigData.outputs.length, this.serverConfigDataOutputs.length)
				);
				Array.prototype.push.apply(selectedRelays, selectedOutputs);
			}
		}

		let selectedConfig: DeviceConfiguration = {
			name: this.serverConfigData.name,
			extraValue: this.serverConfigData.extraValue,
			host: this.serverConfigData.host,
			port: this.serverConfigData.port,
			username: this.serverConfigData.username,
			password: this.serverConfigData.password,
			syncIdentifier: this.serverConfigData.syncIdentifier,
			alarms: selectedAlarms,
			cameras: selectedCameras,
			audios: selectedAudios,
			relays: selectedRelays,
			users: this.serverConfigData.users,
		};

		let serverConfig: ServerConfiguration = {
			serverId: this.selectedServer.serverID,
			extraValue: this.currentServer.extraValue,
			deviceConfiguration: selectedConfig,
		};

		this.addConfig(serverConfig);
	}

	public get extraValue(): string {
		if (this.currentServer) {
			return this.currentServer.extraValue;
		}

		return "";
	}

	private toggleCameras(all: boolean) {
		this.allCamerasSelected = all;
		this.noCamerasSelected = !all;

		this.serverConfigData.cameras.forEach((item, index) => {
			item.selected = all;
		});

		this.refreshCameraUsageDifference();
	}

	private toggleAudios(all) {
		this.allAudiosSelected = all;
		this.noAudiosSelected = !all;

		this.serverConfigData.audios.forEach((item, index) => {
			item.selected = all;
		});
	}

	private toggleAlarms(all) {
		this.allAlarmsSelected = all;
		this.noAlarmsSelected = !all;

		this.serverConfigData.alarms.forEach((item, index) => {
			item.selected = all;
		});
	}

	private toggleOutputs(all) {
		this.allOutputsSelected = all;
		this.noOutputsSelected = !all;

		this.serverConfigData.outputs.forEach((item, index) => {
			item.selected = all;
		});
	}

	private toggleDoors(all) {
		this.allDoorsSelected = all;
		this.noDoorsSelected = !all;

		this.serverConfigData.doors.forEach((item, index) => {
			item.selected = all;
		});
	}

	private get serverDeviceErrorsCount(): number {
		return this.alarmErrorsCount + this.cameraErrorsCount + this.outputErrorsCount + this.doorErrorsCount + this.audioErrorsCount;
	}

	private get alarmErrorsCount(): number {
		let alarmErrorCount = 0;

		if (this.deviceConfigurationErrors) {
			if (this.deviceConfigurationErrors.alarms != null) {
				alarmErrorCount += this.deviceConfigurationErrors.alarms.length;
			}
		}

		return alarmErrorCount;
	}

	private get cameraErrorsCount(): number {
		let cameraErrorCount = 0;

		if (this.deviceConfigurationErrors) {
			if (this.deviceConfigurationErrors.cameras != null) {
				cameraErrorCount += this.deviceConfigurationErrors.cameras.length;
			}
		}

		return cameraErrorCount;
	}

	private get outputErrorsCount(): number {
		let outputErrorCount = 0;

		if (this.deviceConfigurationErrors) {
			if (this.deviceConfigurationErrors.outputs != null) {
				outputErrorCount += this.deviceConfigurationErrors.outputs.length;
			}
		}

		return outputErrorCount;
	}

	private get doorErrorsCount(): number {
		let doorErrorCount = 0;

		if (this.deviceConfigurationErrors) {
			if (this.deviceConfigurationErrors.doors != null) {
				doorErrorCount += this.deviceConfigurationErrors.doors.length;
			}
		}

		return doorErrorCount;
	}

	private get audioErrorsCount(): number {
		let audioErrorCount = 0;

		if (this.deviceConfigurationErrors) {
			if (this.deviceConfigurationErrors.audios != null) {
				audioErrorCount += this.deviceConfigurationErrors.audios.length;
			}
		}

		return audioErrorCount;
	}

	private async refreshCameraUsageDifference(): Promise<void> {
		if (this.isBillingEnabled) {
			await this.fetchSubscription();

			let selectedCount = this.serverConfigData.cameras.filter((c: DeviceConfigurationCameraSelection) => {
				return c.selected;
			}).length;

			this.cameraUsageDifference = selectedCount - this.currentServer.deviceTypeCounts.cameras;
		}
	}
}
