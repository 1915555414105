
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, namespace, State } from "vuex-class";
import { get } from "lodash";
import { NotificationOptions } from "vue-notification";
import { FeaturesList, UserPermissions } from "@/store/types";
import { DeviceTypeCounts, DeviceTypeIdentifier, DeviceDetails, SyncSystem } from "@/store/devices/types";
import CameraPreview from "@/components/device-setup/CameraPreview.vue";
import ChildDeviceTabWrapper from "@/components/device-setup/tabs/ChildDeviceTabWrapper.vue";
import ChildDeviceEdit from "@/components/device-setup/editing/ChildDeviceEdit.vue";
import DeleteChildDeviceModal from "@/components/device-setup/confirmation-modals/DeleteChildDeviceModal.vue";
import DeviceTable from "@/components/device-setup/tables/DeviceTable.vue";
import NavHeader from "@/components/NavHeader.vue";
import DeviceAlarmSetup from '@/components/setup/alarm-setup/DeviceAlarmSetup.vue';
import SyncTable from '@/components/device-setup/sync/SyncTable.vue';
import ServerModal from '@/components/device-setup/ServerModal.vue';

const Devices = namespace("devices");

@Component({
	components: {
		"camera-preview": CameraPreview,
		"child-device-tab-wrapper": ChildDeviceTabWrapper,
		"child-device-edit": ChildDeviceEdit,
		"delete-child-device-modal": DeleteChildDeviceModal,
		"device-table": DeviceTable,
		"sync-table": SyncTable,
		"server-modal": ServerModal,
		"nav-header": NavHeader,
		"device-alarm-setup": DeviceAlarmSetup,
	}
})
export default class DeviceSetup extends Vue {
	@Getter("getPermissions") private permissions: UserPermissions;

	@Devices.Action private getDeviceTypeCounts: (serverId: number | null) => Promise<void>;
	@Devices.Action private fetchSyncSystemCount: () => Promise<void>

	@Devices.Mutation("resetState") private resetDevicesState: () => void;
	@Devices.Mutation("setNotificationOptions") private setNotificationOptions: (
		notificationOptions: NotificationOptions
	) => void;
	@Devices.Mutation private setCameraPreviewModalVisibility: (visible: boolean) => void;

	@Devices.State private device: DeviceDetails;
	@Devices.State private cameraPreviewModalVisible: boolean;
	@Devices.State private deviceTypeCounts: DeviceTypeCounts;
	@Devices.State private editChildDeviceModalVisible: boolean;
	@Devices.State private notificationOptions: NotificationOptions;
	@Devices.State private syncSystems: SyncSystem[];
	@Devices.State private totalSyncSystems: number;
	@State private featuresList: FeaturesList;

	private childDeviceTypeIds: number[] = [];
	private tabIndex: number = 1;

	private async created(): Promise<void> {
		this.addChildDeviceTypes();
		this.resetDevicesState();
		await this.fetchSyncSystemCount();
		await this.getDeviceTypeCounts(null);
	}

	private addChildDeviceTypes(): void {
		for (let deviceTypeIdentifier in DeviceTypeIdentifier) {
			const id = Number(deviceTypeIdentifier);

			if (!isNaN(id)) {
				this.childDeviceTypeIds.push(id);
			}
		}
	}

	private get canViewDeviceSetup(): boolean {
		return (
			get(this.featuresList, ["Devices"]) &&
			(this.permissions.isSystemAdmin || this.permissions.isAccountAdmin ||
			this.permissions.canEditSiteSetup || this.permissions.canViewSiteSetup)
		);
	}

	private get syncSetupEnabled(): boolean {
		return get(this.featuresList, ["Devices", "AdvancedSetup", "SyncSystem"]);
	}

	@Watch("notificationOptions")
	private notify(notificationOptions: NotificationOptions): void {
		if (!notificationOptions) {
			return;
		}

		this.$notify(notificationOptions);
		this.setNotificationOptions(null);
	}

	private handleCameraPreviewHidden(): void {
		this.setCameraPreviewModalVisibility(false);
	}
}
