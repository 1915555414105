
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { SyncSystem, ServerType, ServerDetails, ServerModalStartTab } from "@/store/devices/types";
import {
	CancellableQuery,
	PaginatedSearchQueryParams,
	UserPermissions,
	VueBootstrapField,
	VueBootstrapFieldTableSortEvent
} from "@/store/types";
import { getNewServerDetailsTemplate } from "@/store/devices/templates";
import api from "@/services/api.service";
import { CancelTokenSource } from "axios";
import { getNewCancelTokenSource } from "@/utils/cancellableQueryHelper";
import { debounce } from "lodash";
import { getDebouncePeriod } from "@/utils/debounce-helper";

const Devices = namespace("devices");

interface SyncSystemRow extends SyncSystem {
	readonly?: boolean;
}

@Component({
	components: {
		"vue-perfect-scrollbar": VuePerfectScrollbar
	}
})
export default class SyncTable extends Vue {
	@Prop({ required: false, default: false, type: Boolean })
	private visible: boolean;

	@Devices.State private syncSystems: SyncSystem[];
	@Devices.State private totalSyncSystems: number;
	@Devices.State private serverTypes: ServerType[];
	@Devices.State private serversList: ServerDetails[];

	@Devices.Action private fetchSyncSystems: (
		params: CancellableQuery<PaginatedSearchQueryParams>
	) => Promise<void>;
	@Devices.Action private getServers: () => Promise<void>;
	@Devices.Mutation private setCurrentServer: (server: ServerDetails) => void;
	@Devices.Mutation private setServerModalStartTab: (startTab: ServerModalStartTab) => void;
	@Devices.Mutation setServerModalVisible: (IsVisible: boolean) => void;

	@Getter("getPermissions") private permissions: UserPermissions;

	private searchTerm: string = null;
	private recordsPage: number = 1;
	private recordsPageSize: number = 25;
	private recordsPageSizeMax: number = 200;
	private recordsPageSizeUpdated: number = this.recordsPageSize;
	private sortBy: string = "title";
	private sortDesc: boolean = false;
	private isLoading: boolean = false;
	private selectedSync: SyncSystem = null;
	private showDeleteModal: boolean = false;
	private axiosCancelTokenSource: CancelTokenSource = null;

	private getServerTypeTitle(serverTypeID: number): string {
		return this.serverTypes.find(st => st.serverTypeID == serverTypeID)?.title;
	}

	private fields: VueBootstrapField[] = [
		{
			key: "title",
			label: "Title",
			sortable: true
		},
		{
			key: "serverTypeId",
			label: "Type",
			formatter: serverTypeId => {
				return this.getServerTypeTitle(serverTypeId);
			},
			sortable: true
		},
		{
			key: "host",
			label: "Host",
			sortable: true
		},
		{
			key: "port",
			label: "Port",
			sortable: true
		}
	];

	private async created(): Promise<void> {
		if (this.hasEditPermission) {
			this.addEditPermissionsSpecificFields();
		}

		await this.getSyncSystems();
	}

	private get hasEditPermission(): boolean {
		return this.permissions.isSystemAdmin || ((this.permissions.isAccountAdmin || this.permissions.canEditSiteSetup) && this.permissions.canOverrideGroupSync);
	}

	private addEditPermissionsSpecificFields(): void {
		this.fields.push({ key: "actions", label: "Actions", sortable: false, class: "text-center" });
	}

	private async performSearch(searchTerm: string): Promise<void> {
		this.searchTerm = searchTerm;
		await this.getSyncSystems();
	}

	private handleSearchInput = debounce(async (searchTerm: string): Promise<void> => {
		await this.performSearch(searchTerm);
	}, getDebouncePeriod());

	private async onSortChange(event: VueBootstrapFieldTableSortEvent): Promise<void> {
		if (!this.visible) {
			return;
		}

		this.sortBy = event.sortBy || this.sortBy;
		this.sortDesc = event.sortDesc;

		await this.getSyncSystems();
	}

	private async onPageClick(page: number) {
		if (!this.visible || this.isLoading) {
			return;
		}

		this.recordsPage = page || this.recordsPage;

		await this.getSyncSystems();
	}

	private async onRecordsPerPageClick() {
		let pageSize = +this.recordsPageSizeUpdated;

		if (pageSize) {
			if (pageSize > this.recordsPageSizeMax) {
				pageSize = this.recordsPageSizeMax;
			}
			if (pageSize !== this.recordsPageSize) {
				this.recordsPage = 1;
			}
			this.recordsPageSize = pageSize;
		}
		this.recordsPageSizeUpdated = this.recordsPageSize;
		await this.onPageClick(this.recordsPage);
	}

	private async getSyncSystems(): Promise<void> {
		this.isLoading = true;

		this.axiosCancelTokenSource = getNewCancelTokenSource(this.axiosCancelTokenSource, true);

		const sortBy = this.fields.find(f => f.key === this.sortBy)?.label.replace(/\s/g, "");

		const cancellableQuery: CancellableQuery<PaginatedSearchQueryParams> = {
			params: {
				page: this.recordsPage,
				pageSize: this.recordsPageSize,
				sortBy: sortBy || this.sortBy,
				sortDesc: this.sortDesc,
				searchTerm: this.searchTerm
			},
			cancelTokenSource: this.axiosCancelTokenSource
		};

		try {
			await this.fetchSyncSystems(cancellableQuery);
		} catch (e) {
			this.$notify({
				type: "error",
				title: "Error loading sync systems",
				text: `Unable to load sync systems`
			});
			console.error(e);
		} finally {
			this.isLoading = false;
		}
	}

	private get syncsToDisplay(): SyncSystemRow[] {
		let syncSystems = this.searchTerm ?
		this.syncSystems.filter(ss => ss.title.toLowerCase().includes(this.searchTerm.toLowerCase())) :
		this.syncSystems;

		return syncSystems.map((sync: SyncSystemRow) => {
			sync.readonly = !this.permissions.canOverrideGroupSync && !this.permissions.isSystemAdmin;
			return sync;
		});
	}

	private async getServerForSync(syncSystem: SyncSystem): Promise<ServerDetails> {
		let server = this.serversList.find(sl => sl.syncSystemId == syncSystem.syncSystemId);
		if (!server) {
			server = await api.retrieveServer(syncSystem.serverId);
			server.syncSystemId = syncSystem.syncSystemId;
		}

		let readOnly = !this.permissions.canOverrideGroupSync && !this.permissions.isSystemAdmin;

		return {
			...server,
			readonly: readOnly,
			readonlyMessage: readOnly ? "You do not have permission to edit Sync Devices" : null
		}
	}

	private openServerModal(server: ServerDetails): void {
		if (!server) {
			return;
		}
		this.setServerModalStartTab(ServerModalStartTab.Sync);
		this.setCurrentServer(server);
		this.setServerModalVisible(true);
	}

	private async handleEditClick(syncSystem: SyncSystem): Promise<void> {
		this.openServerModal(await this.getServerForSync(syncSystem));
	}

	private handleAddSync(): void {
		let server = getNewServerDetailsTemplate();
		server.syncSystemId = -1;
		this.openServerModal(server);
	}

	private handleDeleteClick(syncSystem: SyncSystem): void {
		this.selectedSync = syncSystem;
		this.showDeleteModal = true;
	}

	private closeDeleteModal(): void {
		this.selectedSync = null;
		this.showDeleteModal = false;
	}

	private async confirmDelete(syncSystemId: number): Promise<void> {
		this.isLoading = true;
		try {
			this.selectedSync = null;
			this.showDeleteModal = false;
			await api.deleteSyncSystem(syncSystemId);
			await this.getSyncSystems()
			await this.getServers();
		}
		catch (err) {
			console.error(err);
		}

		this.isLoading = false;
	}
}

