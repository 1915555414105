
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace, State } from 'vuex-class';
import { ServerDetails } from '@/store/devices/types';
import GenericTable, { TableHeader } from '@/components/table/generic-table.vue';
import { UserGroup } from '@/store/types';
import { ModalItem } from '@/components/table/generic-update-modal.vue';
import AreaTreeSelect from '@/components/form/AreaTreeSelect.vue';
import { AreaNode } from '@/types/sv-data/groups/AreaNode';
import api from '@/services/api.service';
import {
	GroupSyncModel,
	GroupSyncQueue,
	GroupSyncQueueState,
	SyncStateResponse
} from "@/types/sv-data/devices/SyncSystem";
import GroupSyncConfig from './GroupSyncConfig.vue';
import GroupSyncHistory from './GroupSyncHistory.vue';
import { truncateString } from "@/filters";
import { forEach } from "lodash";

const Devices = namespace("devices");

@Component({
	components: {
		"generic-table": GenericTable,
		"group-sync-history" : GroupSyncHistory
    },
	filters: {
        truncateString
    }
})
export default class GroupSync extends Vue {
	@Devices.State private currentServer: ServerDetails;
	@State private UserGroups: UserGroup[];

    @Prop({type:Boolean, default: false})
    private readonly: Boolean;

	private isPollingState: boolean = false;
	private statePoll: NodeJS.Timeout = null;
	private syncStateArray: number[] = [];

    private getGroupTitle(groupID: number): string {
		return this.UserGroups.find(ug => ug.groupID == groupID)?.title;
	}

    private columns: TableHeader[] = [
		{
			title: "Title",
			key: "title",
			order: 1,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "title",
			searchable: true,
			visible: true,
			dataType: "input",
            isTermLabel: true,
		},
		{
			title: "Area",
			key: "groupTitle",
			order: 2,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "Area",
			searchable: true,
			visible: true,
			dataType: "input",
            isTermLabel: true,
		},
		{
			title: "Identifier",
			key: "identifier",
			order: 3,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "Identifier filter",
			searchable: true,
			visible: true,
			dataType: "input",
            isTermLabel: true,
			useCustomCell: true
		},
		{
			title: "Interval",
			key: "interval",
			order: 4,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "Duration between syncs (hours)",
			searchable: true,
			visible: true,
			dataType: "input",
            isTermLabel: true,
		},
		{
			title: "Enabled",
			key: "enabled",
			order: 5,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "Flag indicating if group sync is enabled",
			searchable: true,
			visible: true,
			dataType: "checkbox",
            isTermLabel: true,
		},
        {
			title: "Last Run",
			key: "lastRun",
			order: 6,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "Last run time",
			searchable: false,
			visible: true,
			dataType: "input",
            isTermLabel: true,
			useCustomCell: true
		},
		{
			title: "Last Sync State",
			key: "lastQueueState",
			order: 7,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "State of last sync",
			searchable: false,
			visible: true,
			dataType: "input",
            isTermLabel: true,
		},
        {
			title: "",
			key: "manualQueue",
			order: 8,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "Actions",
			searchable: false,
			visible: true,
			dataType: "component",
            isTermLabel: true,
			useCustomCell: true,
			width:"50px"
		},
	];

    private get modalItems (): ModalItem[] {
		return [
			{
				title: "Title",
				key: "title",
				readOnly: false,
				required: true,
				dataType: "text",
				maxLength: 200,
			},
            {
				title: "Area",
				key: "groupId",
				dataType: "component",
				readOnly: false,
				required: true,
				data: AreaTreeSelect,
				props: {
					readonly:this.readonly,
					reduce: (area: AreaNode) => area.id,
					clearable: false
				},
			},
            {
				title: "Identifier",
				key: "identifier",
				dataType: "textarea",
				maxLength: 2000,
                placeholder: "Optional - add identifier",
            },
            {
				title: "Interval",
				key: "interval",
				dataType: "number",
                description: "Time in Hours between syncs",
			    min: 1,
				maxLength: 200,
                defaultValue: 24,
            },
			{
				title: "Enabled",
				key: "enabled",
				dataType: "checkbox",
                defaultValue: true,
            },
            {
				title: "Additional Config",
				key: "additionalConfig",
				dataType: "component",
				data: GroupSyncConfig,
				props: {
					readonly:this.readonly
				},
            },
        ]
    }

    private isLoading: boolean = false;
    private isLoadingStates: boolean = false;
    private groupSyncs: GroupSyncModel[] = [];
    private lastQueueStates: GroupSyncQueue[] = [];
	private selectedGroupSyncId: number = null;
	private groupSyncQueue: number = 0;

    private async addOrUpdateGroupSync(groupSync: GroupSyncModel): Promise<void> {
		try
		{
			this.isLoading = true;
			if (groupSync) {
				if (!groupSync.syncSystemId) {
					groupSync.syncSystemId = this.currentServer.syncSystemId;
				}
				await api.addOrUpdateGroupSync(groupSync);
			}
			await this.loadGroupSyncs();
		}
		finally
		{
			this.isLoading = false;
		}
    }

    private async deleteGroupSync(groupSync: GroupSyncModel): Promise<void> {
		try
		{
			this.isLoading = true;
			if (groupSync) {
				await api.deleteGroupSync(groupSync.groupSyncId);
			}

			if (this.selectedGroupSyncId == groupSync.groupSyncId) {
				this.setGroupSyncId(null);
			}
			await this.loadGroupSyncs();

			this.$notify(
				{
					type: "success",
					title: "Group Sync Deleted",
					text: "Successfully deleted group sync '" + groupSync.title + "'"
				});
		}
		catch (err)
		{
			this.$notify({
				type: "error",
				title: "Error",
				text: err.response.data
			});
		}
		finally
		{
			this.isLoading = false;
		}
    }

    private async created(): Promise<void> {
        await this.loadGroupSyncs();
    }

	private async loadLastQueueStates(): Promise<void>
	{
		try
		{
			this.isLoadingStates = true;
			var lastStates = await api.retrieveLastSyncQueueStates(this.currentServer.syncSystemId);
			if (lastStates) {
				this.lastQueueStates = lastStates;
			}
		}
		catch (err)
		{
			console.error(err)
		}
		finally
		{
			this.isLoadingStates = false;
		}
	}

	private retrieveFriendlyState(groupSyncId: number): string {
		var state = this.lastQueueStates.find(lq => lq.groupSyncId == groupSyncId)?.state;

		return this.convertQueueStateToFriendlyText(state);
	}
	private convertQueueStateToFriendlyText(state: string): string {
		switch (state)
		{
			case GroupSyncQueueState.preQueued:
				return "Waiting to be picked up";
			case GroupSyncQueueState.queued:
				return "Active queue";
			case GroupSyncQueueState.reQueue:
				return "Active queue";
			case GroupSyncQueueState.processing:
				return "Processing...";
			case GroupSyncQueueState.failed:
				return "Failed to sync";
			case GroupSyncQueueState.processed:
				return "Processed";
			case GroupSyncQueueState.partialProcessed:
				return "Partially Processed - some entities were not synced";
			case GroupSyncQueueState.noData:
				return "No data returned from Device";
			default:
				return "";
		}
	}

	private groupSyncQueueable(groupSyncId: number): boolean {
		let state = this.lastQueueStates.find(lq => lq.groupSyncId == groupSyncId)?.state;

		switch (state)
		{
			case GroupSyncQueueState.preQueued:
				return false;
			case GroupSyncQueueState.queued:
				return false;
			case GroupSyncQueueState.reQueue:
				return false;
			case GroupSyncQueueState.processing:
				return false;
			default:
				return true;
		}
	}

    @Watch("currentServer", { deep: true })
    private async loadGroupSyncs(): Promise<void> {
        if (!this.currentServer || !this.currentServer.syncSystemId) {
            this.groupSyncs = [];
            return;
        }
		try
		{
			this.isLoading = true;
			var groupSyncs = await api.groupSyncsForSyncSystem(this.currentServer.syncSystemId);
			if (!groupSyncs) {
				groupSyncs = [];
			}
			await this.loadLastQueueStates();
			this.groupSyncs = groupSyncs.map(gs => {
				return ({
					...gs,
					manualQueue: null,
					groupTitle: this.getGroupTitle(gs.groupId),
					lastQueueState: this.retrieveFriendlyState(gs.groupSyncId),
					queueable: this.groupSyncQueueable(gs.groupSyncId)
				})
			});
		}
		catch (err)
		{
			console.error(err);
		}
		finally
		{
			this.isLoading = false;
		}
    }

	private async syncNow(groupSyncId: number): Promise<void> {
		try
		{
			if(!this.syncStateArray.find(g => g === groupSyncId))
			{
				this.syncStateArray.push(groupSyncId);
			}

			await api.queueGroupSync(groupSyncId);

			const groupSync = this.groupSyncsForTable.firstOrDefault((g: GroupSyncModel) => g.groupSyncId === groupSyncId);
			if (groupSync) {
				groupSync.lastQueueState = this.convertQueueStateToFriendlyText(GroupSyncQueueState.preQueued);
				groupSync.enabled = false;
			}

			this.isPollingState = true;

			await this.pollGroupSyncState();
		}
		catch(err)
		{
			console.error(err);
		}
	}

	private async pollGroupSyncState(): Promise<void>{

		if (!this.isPollingState){
			clearTimeout(this.statePoll)
			return;
		}
		let currentState : SyncStateResponse = await api.RetrieveLatestQueueStateForGroupSync(this.syncStateArray[0]);

		this.statePoll = setTimeout(() => {this.pollGroupSyncState()},1000);

		let reloadGroupSyncs: boolean = false;

		const groupSync = this.groupSyncsForTable.firstOrDefault((g: GroupSyncModel) => g.groupSyncId === currentState.groupSyncId);
		if (groupSync) {
			groupSync.lastQueueState = this.convertQueueStateToFriendlyText(currentState.state);
		}

		if(!reloadGroupSyncs && currentState.state !== GroupSyncQueueState.preQueued
			&& currentState.state !== GroupSyncQueueState.queued
			&& currentState.state !== GroupSyncQueueState.processing) {
			reloadGroupSyncs = true;
			this.syncStateArray.remove(currentState.groupSyncId);
		}

		if(reloadGroupSyncs) {
			await this.loadGroupSyncs();

			if (this.selectedGroupSyncId === currentState.groupSyncId) {
				this.groupSyncQueue++;
			}
		}

		if(this.syncStateArray?.length === 0)
		{
			clearTimeout(this.statePoll)
			this.isPollingState = false;
		}
	}

	beforeDestroy() : void {
		this.isPollingState = false;
		clearTimeout(this.statePoll);
		this.statePoll = null;
	}

	private setGroupSyncId(groupSyncId: number | null): void {
		this.selectedGroupSyncId = groupSyncId;
	}

	private get groupSyncsForTable(): GroupSyncModel[] {
		return this.selectedGroupSyncId ?
			this.groupSyncs.filter(gs => gs.groupSyncId == this.selectedGroupSyncId)
			: this.groupSyncs;
	}
}
