
import { Component, Mixins, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import vselect3 from "vselect3";
import { isEqual } from "lodash";

import ChildDeviceEditMixin from "@/components/device-setup/editing/ChildDeviceEditMixin";
import { RelaySettingsMeta } from "@/store/devices/types";

const Devices = namespace("devices");

@Component({
	components: {
		"v-select-3": vselect3
	},
	validations: {
		device: { required },
		hasSettingsMetaChanged: {
			required: requiredIf(function() {
				return this.isEditing && isEqual(this.device.relaySettings, this.originalRelaySettings);
			})
		}
	}
})
export default class RelayEdit extends Mixins(ChildDeviceEditMixin, validationMixin) {
	private originalRelaySettings: RelaySettingsMeta = null;

	public created(): void {
		this.originalRelaySettings = { ...this.device.relaySettings };
	}
}
